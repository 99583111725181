// eslint-disable-next-line import/no-cycle
import { patch, post } from '@helpers/fetch-wrapper'
import {
  PayloadAction,
  bindActionCreators,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import {
  ATTACH_JOB_POST_TO_EVENT_URL,
  CREATE_EVENT,
  EVENT_BY_ID_URL,
  EVENT_INVITES_URL,
} from '@constants/url'

import { LocationProps, OptionType } from '@custom-types/index'
import { PostInterviewInviteRequest } from '@custom-types/requests'

export type CreateInterviewProps = {
  jobTitle: string
  data: {
    companyId: string
    eventName: string
    startTimestamp: string
    endTimestamp: string
    notes: string
    inviteeRequirements: { item: string }[]

    mapPlaceId?: string
    locationTitle?: string
    locationAddress?: string
    latitude?: number
    longitude?: number

    isOnline?: boolean
    onlineLink?: string | null
  }
}

export type UpdateInterviewProps = CreateInterviewProps & {
  eventId: string
  jobPostId?: string
}

export const createInterview = createAsyncThunk(
  'interview/createInterview',
  async (reqBody: CreateInterviewProps['data'], { rejectWithValue }) => {
    try {
      const res = await post(CREATE_EVENT, reqBody)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const postInterviewInvite = createAsyncThunk(
  'interview/postInvite',
  async (data: PostInterviewInviteRequest, { rejectWithValue }) => {
    try {
      const res = await post(EVENT_INVITES_URL(data))
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateInterview = createAsyncThunk(
  'interview/updateInterview',
  async (
    {
      reqBody,
      eventId,
    }: { eventId: string; reqBody: Record<PropertyKey, unknown> },
    { rejectWithValue }
  ) => {
    try {
      const res = await patch(EVENT_BY_ID_URL(eventId), reqBody)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const attachJobPostEvent = createAsyncThunk(
  'interview/attachJobPostEvent',
  async (
    { eventId, jobPostId }: { eventId: string; jobPostId: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await patch(
        ATTACH_JOB_POST_TO_EVENT_URL(eventId, jobPostId || '')
      )
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type StateProps = {
  order: 'Latest' | 'Oldest' | 'ApplicantCount'
  status: 'All'
  jobLocation: LocationProps | string | OptionType
  isLoadingCreateInterview: boolean
  interviewCreationModal: {
    open: boolean
    interviewId: string | null
  }
  openEventModal: boolean
}

export const initialState: StateProps = {
  order: 'Latest',
  status: 'All',
  jobLocation: '',
  isLoadingCreateInterview: false,
  interviewCreationModal: {
    open: false,
    interviewId: null,
  },
  openEventModal: false,
}

export const interviewsSlice = createSlice({
  name: 'interviews',
  initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<StateProps['order']>) => {
      state.order = action.payload
    },
    setStatus: (state, action: PayloadAction<StateProps['status']>) => {
      state.status = action.payload
    },
    setIsLoadingCreateInterview: (
      state,
      action: PayloadAction<StateProps['isLoadingCreateInterview']>
    ) => {
      state.isLoadingCreateInterview = action.payload
    },
    setInterviewModalCreationState(
      state,
      action: PayloadAction<Partial<StateProps['interviewCreationModal']>>
    ) {
      state.interviewCreationModal = {
        ...state.interviewCreationModal,
        ...action.payload,
      }
    },
    setOpenEventModal: (
      state,
      action: PayloadAction<StateProps['openEventModal']>
    ) => {
      state.openEventModal = action.payload
    },
  },
})

export const interviewActions = interviewsSlice.actions

export const useInterviewActions = () => {
  const dispatch = useDispatch()

  return useMemo(
    () => bindActionCreators(interviewActions, dispatch),
    [dispatch]
  )
}

export default interviewsSlice.reducer
