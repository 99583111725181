import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type StateProps = {
  accessToken?: string
  refreshToken?: string
}

const initialState: StateProps = {
  accessToken: undefined,
  refreshToken: undefined,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveAuthData(state, action: PayloadAction<StateProps>) {
      const authData = action?.payload

      state.accessToken = authData.accessToken
      state.refreshToken = authData.refreshToken
    },
    logout(state) {
      state.accessToken = ''
      state.refreshToken = ''
    },
  },
})

export const authActions = authSlice.actions

export default authSlice.reducer
