/* eslint-disable import/prefer-default-export */
import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { RedeemCodeAPIResponse } from '@features/coins/types'

import { GroupProductKey, TopupPackage } from '@custom-types/coins'

export type CoinsState = {
  openTransactionHistoryDrawer: boolean
  selectedTransactionId: string
  topupDrawer: {
    open: boolean
    tab: 'topup' | 'redeem'
    topupPackage: TopupPackage | null
    loadingPayment: boolean
    isSubmitted: boolean
  }
  productCode: string
  redeemCodeDetail?: RedeemCodeAPIResponse
  validateCoinAmmount?: number
  expiredModal: {
    open: boolean
  }
  priceListDrawer: {
    tab: GroupProductKey
    open: boolean
  }
}

const initialState: CoinsState = {
  openTransactionHistoryDrawer: false,
  selectedTransactionId: '',
  topupDrawer: {
    tab: 'topup',
    open: false,
    topupPackage: null,
    loadingPayment: false,
    isSubmitted: false,
  },
  productCode: '',
  expiredModal: {
    open: false,
  },
  priceListDrawer: {
    tab: 'Candidate:Search',
    open: false,
  },
}

export const coinsSlice = createSlice({
  name: 'coins',
  initialState,
  reducers: {
    setProductListDrawerTab(
      state,
      action: PayloadAction<CoinsState['priceListDrawer']['tab']>
    ) {
      state.priceListDrawer.tab = action.payload
    },
    setCoinsPriceListDrawer(
      state,
      action: PayloadAction<CoinsState['priceListDrawer']['open']>
    ) {
      state.priceListDrawer.open = action.payload
    },
    setTransactionHistoryDrawer(
      state,
      action: PayloadAction<CoinsState['openTransactionHistoryDrawer']>
    ) {
      state.openTransactionHistoryDrawer = action.payload
    },
    setSelectedTransactionId(
      state,
      action: PayloadAction<CoinsState['selectedTransactionId']>
    ) {
      state.selectedTransactionId = action.payload
    },
    setTopupDrawerState(
      state,
      action: PayloadAction<Partial<CoinsState['topupDrawer']>>
    ) {
      state.topupDrawer = {
        ...state.topupDrawer,
        ...action.payload,
      }
    },
    resetTopupDrawerState(state) {
      state.topupDrawer = initialState.topupDrawer
    },
    setProductCode(
      state,
      action: PayloadAction<Partial<CoinsState['productCode']>>
    ) {
      state.productCode = action.payload
    },
    setRedeemCodeDetailState(
      state,
      action: PayloadAction<CoinsState['redeemCodeDetail']>
    ) {
      state.redeemCodeDetail = action.payload
    },
    setValidateCoinAmmountState(
      state,
      action: PayloadAction<CoinsState['validateCoinAmmount']>
    ) {
      state.validateCoinAmmount = action.payload
    },
    setCoinsExpiredModalState(
      state,
      action: PayloadAction<Partial<CoinsState['expiredModal']>>
    ) {
      state.expiredModal = { ...state.expiredModal, ...action.payload }
    },
  },
})

const { actions } = coinsSlice

export const useCoinsActions = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators(actions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}
