// eslint-disable-next-line import/no-cycle
import { _delete } from '@helpers/fetch-wrapper'
import {
  PayloadAction,
  bindActionCreators,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { INTERVIEW_DETAIL_URL } from '@constants/url'

export type StateProps = {
  page: number
  inviteStatus:
    | '["Invited"]'
    | '["Accepted"]'
    | '["Rejected"]'
    | '["Confirmed"]'
  jobPostId: string
  jobTitle: string
  candidateDrawer: {
    candidateId: string
    open: boolean
  }
  interviewDrawer: {
    open: boolean
  }
}

const initialState: StateProps = {
  page: 1,
  inviteStatus: '["Invited"]',
  jobPostId: '',
  jobTitle: 'Semua loker',
  candidateDrawer: {
    candidateId: '',
    open: false,
  },
  interviewDrawer: {
    open: false,
  },
}

export const endChat = createAsyncThunk(
  'event/deleteEvent',
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const res = await _delete(INTERVIEW_DETAIL_URL(id))
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const interviewDetailSlice = createSlice({
  name: 'create-job',
  initialState,
  reducers: {
    setPage: (state, actions: PayloadAction<StateProps['page']>) => {
      state.page = actions.payload
    },
    setInviteStatus: (
      state,
      actions: PayloadAction<StateProps['inviteStatus']>
    ) => {
      state.inviteStatus = actions.payload
    },
    setJobPostId: (state, actions: PayloadAction<StateProps['jobPostId']>) => {
      state.jobPostId = actions.payload
    },
    setInterviewDrawer(
      state,
      action: PayloadAction<StateProps['interviewDrawer']>
    ) {
      state.interviewDrawer = action.payload
    },
    setJobTitle(state, action: PayloadAction<string>) {
      state.jobTitle = action.payload
    },
    setCandidateDrawer(
      state,
      action: PayloadAction<StateProps['candidateDrawer']>
    ) {
      state.candidateDrawer = action.payload
    },
  },
})

export const interviewDetailActions = interviewDetailSlice.actions

export const useInterviewDetailActions = () => {
  const dispatch = useDispatch()

  return useMemo(
    () => bindActionCreators(interviewDetailActions, dispatch),
    [dispatch]
  )
}

export default interviewDetailSlice.reducer
