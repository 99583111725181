/* eslint-disable import/no-cycle */
import axios from '@helpers/axios-instance'
import {
  authHeader,
  get,
  handleResponse,
  patch,
  post,
  put,
} from '@helpers/fetch-wrapper'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'

// import { ACCESS_EDIT_COMPANY_PROFILE } from '@constants/amplitude-event'
import { BRANCH_COMPLETE_REGISTRATION } from '@constants/branch-event'
import {
  COMPANY_BYID_URL,
  COMPANY_CONTACT_URL,
  EMPLOYERS_PROFILE_URL,
  GET_INDUSTRIES_URL,
  POST_COMPANY_LOGO_URL,
} from '@constants/url'

import { setBranchEvent } from '@utils/branch'

import { RootState } from '@store/index'

import { CompanyProps } from '@custom-types/index'

export type SendbirdConfigurationProps = {
  accessToken?: string
  userId: string
}

export type StateProps = {
  id?: string
  isNewUser?: boolean
  fullName: string
  phoneNumber: string
  company?: CompanyProps
  hasViewedStartModal?: boolean
  sendbirdConfiguration?: SendbirdConfigurationProps
  email?: string
  contactType?: string
  profilePicture?: string
  profilePictureURL?: string
  bio?: string
  kycStatus?: string
  isPhantom?: boolean
  isOps?: boolean
  editProfilePage?: 'about-user' | 'about-company'
}

export type PostProfileProps = Omit<StateProps, 'id' | 'phoneNumber'>

export const getContact = createAsyncThunk(
  'profile/getContact',
  async (companyId: string, { rejectWithValue }) => {
    try {
      const res = await get(COMPANY_CONTACT_URL(companyId))
      return { type: res.data.type, value: res.data.value }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getProfile = createAsyncThunk(
  'profile/getProfile',
  async (_, thunkApi) => {
    try {
      thunkApi.getState()
      // const companyId = (state as RootState)?.profileReducer?.company
      // 	?.id as string

      const res = await get(EMPLOYERS_PROFILE_URL)
      // if (companyId) {
      // 	await thunkApi.dispatch(getContact(companyId)).unwrap()
      // }

      return res.data
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const postProfile = createAsyncThunk(
  'onboard/postProfile',
  async (profileData: PostProfileProps, thunkApi) => {
    try {
      const res = await post(EMPLOYERS_PROFILE_URL, profileData)
      const userData = res?.data

      await setBranchEvent(BRANCH_COMPLETE_REGISTRATION, {
        fullName: userData?.fullName,
        companyName: userData?.company?.name,
        industry: userData?.company?.industry?.name,
      })

      const companyId = res?.data?.company?.id

      if (companyId) {
        await thunkApi.dispatch(getContact(companyId)).unwrap()
      }

      return res.data
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async (profileData: Partial<PostProfileProps>, thunkApi) => {
    try {
      const res = await patch(EMPLOYERS_PROFILE_URL, profileData)
      const { data } = res

      return data
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const updateCompany = createAsyncThunk(
  'profile/updateCompany',
  async (
    companyData: Partial<Omit<CompanyProps, 'id' | 'employee'>>,
    thunkApi
  ) => {
    try {
      const state = thunkApi.getState()
      // const profile = (state as RootState)?.profileReducer
      const companyId = (state as RootState)?.profileReducer?.company
        ?.id as string

      await put(COMPANY_BYID_URL(companyId), companyData)
      return companyData
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const uploadLogo = createAsyncThunk(
  'profile/uploadLogo',
  async (logoData: File, thunkApi) => {
    try {
      const state = thunkApi.getState()
      const companyId = (state as RootState)?.profileReducer?.company
        ?.id as string
      const config = {
        headers: {
          ...authHeader(),
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        },
      }

      const formData = new FormData()
      formData.append('image', logoData)
      const res = await axios.post(
        POST_COMPANY_LOGO_URL(companyId),
        formData,
        config
      )

      return (handleResponse(res) as AxiosResponse).data
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const getIndustries = createAsyncThunk(
  'profile/getIndustries',
  async (_, { rejectWithValue }) => {
    try {
      const res = await get(GET_INDUSTRIES_URL)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const initialState: StateProps = {
  isNewUser: undefined,
  fullName: '',
  company: undefined,
  phoneNumber: '',
  hasViewedStartModal: undefined,
  sendbirdConfiguration: undefined,
  email: '',
  contactType: '',
  profilePicture: '',
  profilePictureURL: '',
  bio: '',
  kycStatus: '',
  editProfilePage: 'about-user',
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    saveNewProfile(state, action: PayloadAction<StateProps>) {
      const newProfile = action?.payload

      state.id = newProfile?.id
      state.fullName = newProfile?.fullName
      state.isNewUser = newProfile?.isNewUser
      state.company = newProfile?.company
      state.sendbirdConfiguration = newProfile?.sendbirdConfiguration
      state.profilePicture = newProfile?.profilePicture
      state.profilePictureURL = newProfile?.profilePictureURL
      state.kycStatus = newProfile?.kycStatus
      state.isPhantom = newProfile?.isPhantom
    },
    setCompany(state, action: PayloadAction<StateProps['company']>) {
      const newCompany = action?.payload
      state.company = newCompany
    },
    setIsNewUser(state, action: PayloadAction<StateProps['isNewUser']>) {
      state.isNewUser = action.payload
    },
    removeProfile(state) {
      state.isNewUser = initialState.isNewUser
      state.fullName = initialState.fullName
      state.phoneNumber = initialState.phoneNumber
      state.company = initialState.company
      state.sendbirdConfiguration = initialState.sendbirdConfiguration
      state.id = initialState.id
      state.hasViewedStartModal = initialState.hasViewedStartModal
      state.kycStatus = initialState.kycStatus
      state.isPhantom = initialState.isPhantom
    },
    setOpsStatus(state, action: PayloadAction<boolean>) {
      state.isOps = action.payload
    },
    setEditProfilePage(
      state,
      action: PayloadAction<StateProps['editProfilePage']>
    ) {
      state.editProfilePage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getProfile.fulfilled,
        (state, action: PayloadAction<StateProps>) => {
          const newProfile = action?.payload

          state.fullName = newProfile?.fullName
          state.isNewUser = newProfile?.isNewUser
          state.company = newProfile?.company
          state.hasViewedStartModal = newProfile?.hasViewedStartModal
          state.sendbirdConfiguration = newProfile?.sendbirdConfiguration
          state.bio = newProfile?.bio
          state.profilePicture = newProfile?.profilePicture
          state.isPhantom = newProfile?.isPhantom
        }
      )
      .addCase(
        postProfile.fulfilled,
        (state, action: PayloadAction<StateProps>) => {
          const newProfile = action?.payload

          state.fullName = newProfile?.fullName
          state.isNewUser = newProfile?.isNewUser
          state.company = newProfile?.company
        }
      )
      .addCase(
        updateProfile.fulfilled,
        (state, action: PayloadAction<StateProps>) => {
          const newProfile = action.payload

          state.fullName = newProfile?.fullName
          state.isNewUser = newProfile?.isNewUser
          state.hasViewedStartModal = newProfile.hasViewedStartModal
          state.bio = newProfile?.bio
          state.profilePicture = newProfile?.profilePicture
        }
      )
      .addCase(getContact.fulfilled, (state, action) => {
        const newContact = action.payload
        state.contactType = newContact.type
        if (newContact.type === 'PhoneNumber') {
          state.phoneNumber = newContact.value
        } else {
          state.email = newContact.value
        }
      })
  },
})

export const profileActions = profileSlice.actions

export default profileSlice.reducer
