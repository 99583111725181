import React from 'react'

import useEmployerProfileData from '@hooks/use-employer-profile-data'
import useHeartbeat from '@hooks/use-heartbeat/useHeartbeat'

import { initAmplitude } from '@utils/amplitude'
import { initBranch } from '@utils/branch'

const initializeUtils = () => {
  initAmplitude()
  initBranch()
}

const InitiateUserGlobals = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useEmployerProfileData()

  useHeartbeat({ shouldStartHeartbeat: Boolean(data) })

  React.useEffect(() => {
    if (data) {
      initializeUtils()
    }
  }, [data])

  return children
}

export default InitiateUserGlobals
