/* eslint-disable import/no-cycle */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'

// import jwtDecode from 'jwt-decode'
import { generateOtpService } from '@features/login/services'
import { profileActions } from '@features/profile/reducers'
import {
  // getContact,
  getProfile,
} from '@features/profile/reducers/ProfileReducers'

// import { BRANCH_LOGIN } from '@constants/branch-event'
import countryCode from '@constants/country-code'
import { Access } from '@constants/event-tracking'
import {
  ATMA_EMPLOYER_ACCESS_TOKEN,
  ATMA_EMPLOYER_REFRESH_TOKEN,
} from '@constants/storage-items'

/* import {
	ACCESS_CREATED_NEW_ACCOUNT,
	ACCESS_LOGGED_IN,
	ACCESS_VERIFIED_OTP,
} from '@constants/event-tracking'

import { setBranchEvent } from '@utils/branch' */
import trackEvents, { setUserId } from '@utils/track-events'

import {
  GenerateMagicLinkProps,
  GenerateOtpProps,
  VerifyOtpProps,
  postMagicLink,
  verifyOtpService,
} from '../services/LoginServices'

type JWTProps = {
  userId: string
}

export type StateProps = {
  page: 'loginOptions' | 'verifyOtp' | 'verifyEmail'
  selectedLoginIndex: number
  phoneNumber: string
  countryCode: string
  token?: string
  otp: string
  email: string
}

const initialState: StateProps = {
  page: 'loginOptions',
  selectedLoginIndex: 0,
  phoneNumber: '',
  countryCode: countryCode() as string,
  token: '',
  otp: '',
  email: '',
}

export const generateOtp = createAsyncThunk(
  'login/generateOtp',
  async (
    { otpData, isPs }: { otpData: GenerateOtpProps; isPs: boolean },
    { rejectWithValue }
  ) => {
    try {
      const data = await generateOtpService(otpData, isPs)

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const verifyToken = createAsyncThunk(
  'login/verityOtp',
  async (
    { otpData, isPs }: { otpData: VerifyOtpProps; isPs: boolean },
    thunkApi
  ) => {
    try {
      const authData = await verifyOtpService(otpData, isPs)
      const { userId } = jwtDecode<JWTProps>(authData?.accessToken)

      setUserId(userId)
      trackEvents(Access.LOGGED_IN)

      localStorage.setItem(ATMA_EMPLOYER_ACCESS_TOKEN, authData?.accessToken)
      localStorage.setItem(ATMA_EMPLOYER_REFRESH_TOKEN, authData?.refreshToken)

      const userData = await thunkApi.dispatch(getProfile()).unwrap()
      /* const contactDetails = await thunkApi
				.dispatch(getContact(userData.companyId))
				.unwrap() */

      /* if (userData?.isNewUser === true) {
				trackEvents(ACCESS_CREATED_NEW_ACCOUNT, {
					phone_number:
						contactDetails.type !== 'Email' ? contactDetails.value : null,
					email: contactDetails.type === 'Email' ? contactDetails.value : null,
				})
			} else {
				trackEvents(ACCESS_LOGGED_IN)
			} */

      return thunkApi.dispatch(profileActions.saveNewProfile(userData))
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }
)

export const generateMagicLink = createAsyncThunk(
  'login/generateMagicLink',
  async (emailData: GenerateMagicLinkProps, { rejectWithValue }) => {
    try {
      const res = await postMagicLink(emailData)
      return res
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<StateProps['page']>) {
      state.page = action.payload
    },
    setPhoneNumber(
      state,
      action: PayloadAction<{ phoneNumber: string; countryCode: string }>
    ) {
      state.phoneNumber = action.payload.phoneNumber
      state.countryCode = action.payload.countryCode
    },
    setEmail(state, action: PayloadAction<{ email: string }>) {
      state.email = action.payload.email
    },
    setSelectedLoginIndex(
      state,
      action: PayloadAction<StateProps['selectedLoginIndex']>
    ) {
      state.selectedLoginIndex = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateOtp.fulfilled, (state, action) => {
      state.token = action.payload.token
    })
  },
})

export const loginActions = loginSlice.actions

export default loginSlice.reducer
