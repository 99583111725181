/* eslint-disable import/no-cycle */
import { _delete, get, patch, post, put } from '@helpers/fetch-wrapper'
import {
  PayloadAction,
  bindActionCreators,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { ApplicantFilterProps } from '@features/filters/reducers'

import { ApplicantStatusProps } from '@constants/applicant-status'
import {
  BULK_CANDIDATE_APPLICATION_STATUS_URL,
  CANDIDATE_APPLICATION_STATUS_URL,
  CREATE_JOB_APPLICATION_URL,
  DELETE_COMMENTS_URL,
  INVITE_CANDIDATE_APPLY_URL,
  JOB_CANDIDATES_BYID_URL,
  JOB_DETAIL_URL,
  JOB_DUPLICATE_EXPIRED_JOB_URL,
  JOB_POSTS_BYID_URL,
  POST_COMMENTS_URL,
} from '@constants/url'

import { ApplicantType } from '@custom-types/applicant'

import { JobDetailProps } from '../types'
import { ApplicantProps } from '../types/applicant'

export type ApplicantFileProps = {
  fileUrl: string
  documentAction: 'VIEWED' | 'DOWNLOAD'
}

export type JobReducerState = {
  page: 'job-detail' | 'candidate-selection' | 'recruitment-process'
  jobDetail?: JobDetailProps
  jobDetailPage: {
    selectedApplicant?: ApplicantType['candidate']
    applicationIds: ApplicantProps['application']['id'][]
    nextApplicantStatus?: ApplicantStatusProps
    chatIcon: string
    whatsappIcon: string
    webProfileCVIcon: string
    isPlayerOpen: boolean
    isRecommendedInfoShown: boolean
    selectedApplicantFilter: Partial<ApplicantFilterProps> | null
    applicantFilterIndex: number | null
    applicantFile: ApplicantFileProps | null
    selectedTabIndex: number
  }
  selectionPage: {
    numberOfCandidates: number
  }
  jobListingQueries: {
    status: string[]
    order: 'Latest' | 'Oldest' | 'ApplicantCount'
    searchText?: string
  }
  question: string
  isQuestionModalOpen: boolean
  modalId: string
  newPostHighlight: string
  selectedCandidate: string[]
  jobDetailDrawer: {
    open: boolean
    jobId: string
  }
}

export const getApplicants = createAsyncThunk(
  'job-detail/candidates',
  async (
    {
      jobId,
      searchStatus,
      searchText,
    }: {
      jobId: string
      searchStatus: string
      searchText: string
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await get(
        JOB_CANDIDATES_BYID_URL(jobId, searchStatus, searchText)
      )

      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getJobDetail = createAsyncThunk(
  'job/getJobDetail',
  async (companyId: string, { rejectWithValue }) => {
    try {
      const res = await get(JOB_DETAIL_URL(companyId))
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getJobCandidates = createAsyncThunk(
  'job-detail/candidates',
  async (jobId: string, { rejectWithValue }) => {
    try {
      const res = await get(JOB_CANDIDATES_BYID_URL(jobId, '', ''))
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const duplicateExpiredJob = createAsyncThunk(
  'job-detail/duplicateExpiredJob',
  async (jobId: string, { rejectWithValue }) => {
    try {
      const res = await post(JOB_DUPLICATE_EXPIRED_JOB_URL(jobId), {})
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateJobDetail = createAsyncThunk(
  'job-detail/updateJobDetail',
  async (
    {
      jobId,
      isActive,
      disableComment,
    }: { jobId: string; isActive?: boolean; disableComment?: boolean },
    { rejectWithValue }
  ) => {
    try {
      const updateJobDetailData: {
        isActive?: boolean
        disableComment?: boolean
      } = {
        isActive,
        disableComment,
      }
      const res = await patch(JOB_POSTS_BYID_URL(jobId), updateJobDetailData)

      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const bulkUpdateApplicantStatus = createAsyncThunk(
  'job-detail/bulk-applicant-status',
  async (
    {
      jobId,
      status,
      applicationIds,
    }: {
      jobId: string
      status: string
      applicationIds: string[]
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await put(
        BULK_CANDIDATE_APPLICATION_STATUS_URL(jobId, status),
        { applicationIds }
      )

      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type UpdateStatusProps = {
  jobId: string
  candidateId: string
  status: string
  applicationId: string
}

type UpdateApplicantStatusProps = Omit<UpdateStatusProps, 'candidateId'>

export const updateApplicantStatus = createAsyncThunk(
  'job-detail/update-applicant-status',
  async (
    { jobId, applicationId, status }: UpdateApplicantStatusProps,
    { rejectWithValue }
  ) => {
    try {
      const res = await put(
        CANDIDATE_APPLICATION_STATUS_URL(jobId, applicationId, status)
      )
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type UpdateRNAStatus = Omit<UpdateStatusProps, 'applicationId'>

export const updateRecommendedCandidateStatus = createAsyncThunk(
  'job-detail/update-recommended-candidate-status',
  async (
    { jobId, candidateId, status }: UpdateRNAStatus,
    { rejectWithValue }
  ) => {
    try {
      const res = await post(CREATE_JOB_APPLICATION_URL(jobId), {
        candidateId,
        status,
      })
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type PostInviteRNAProps = Pick<UpdateStatusProps, 'jobId' | 'candidateId'>

export const postInviteRNA = createAsyncThunk(
  'job-detail/post-invite-rna',
  async ({ jobId, candidateId }: PostInviteRNAProps, { rejectWithValue }) => {
    try {
      const res = await post(INVITE_CANDIDATE_APPLY_URL(jobId, candidateId))
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const initialState: JobReducerState = {
  page: 'job-detail',
  jobDetail: undefined,
  jobDetailPage: {
    selectedApplicant: undefined,
    applicationIds: [],
    nextApplicantStatus: undefined,
    chatIcon: '/assets/icons/ic_chat.svg',
    whatsappIcon: '/assets/icons/ic_whatsapp_green.svg',
    webProfileCVIcon: '/assets/icons/ic_web_profile_cv.svg',
    isPlayerOpen: false,
    isRecommendedInfoShown: true,
    selectedApplicantFilter: null,
    applicantFilterIndex: null,
    applicantFile: null,
    selectedTabIndex: 0,
  },
  selectionPage: {
    numberOfCandidates: 6,
  },
  jobListingQueries: {
    status: [],
    order: 'Latest',
  },
  isQuestionModalOpen: false,
  question: '',
  modalId: '',
  newPostHighlight: '',
  selectedCandidate: [],
  jobDetailDrawer: {
    jobId: '',
    open: false,
  },
}

type PostCommentProps = {
  jobPostId: string
  commentText: string
  parentCommentId?: string
}

export const postComment = createAsyncThunk(
  'job/postComment',
  async (data: PostCommentProps, { rejectWithValue }) => {
    try {
      const commentData: { text: string; parentCommentId?: string } = {
        text: data.commentText,
      }

      if (data.parentCommentId) {
        commentData.parentCommentId = data.parentCommentId
      }

      const res = await post(POST_COMMENTS_URL(data.jobPostId), commentData)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type DeleteCommentProps = {
  jobPostId: string
  commentId: string
}

export const deleteComment = createAsyncThunk(
  'job/deleteComment',
  async (data: DeleteCommentProps, { rejectWithValue }) => {
    try {
      const res = await _delete(
        DELETE_COMMENTS_URL(data.jobPostId, data.commentId)
      )
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const jobSlice = createSlice({
  name: 'job-detail',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<JobReducerState['page']>) => {
      state.page = action.payload
    },
    setJobListingQueries(
      state,
      action: PayloadAction<Partial<JobReducerState['jobListingQueries']>>
    ) {
      state.jobListingQueries = {
        ...state.jobListingQueries,
        ...action.payload,
      }
    },
    setNumberOfCandidates: (state, action: PayloadAction<number>) => {
      state.selectionPage.numberOfCandidates = action.payload
    },
    decreaseNumberOfCandidates: (state) => {
      state.selectionPage.numberOfCandidates -= 1
    },
    setJobDetail: (
      state,
      action: PayloadAction<JobReducerState['jobDetail']>
    ) => {
      state.jobDetail = action.payload
    },
    setIsQuestionModal: (
      state,
      action: PayloadAction<JobReducerState['isQuestionModalOpen']>
    ) => {
      state.isQuestionModalOpen = action.payload
    },
    setQuestion: (
      state,
      action: PayloadAction<JobReducerState['question']>
    ) => {
      state.question = action.payload
    },
    setModalId: (state, action: PayloadAction<JobReducerState['modalId']>) => {
      state.modalId = action.payload
    },
    changeNewPostHighlight: (
      state,
      action: PayloadAction<JobReducerState['newPostHighlight']>
    ) => {
      state.newPostHighlight = action.payload
    },
    changeSelectedCandidate: (
      state,
      action: PayloadAction<JobReducerState['selectedCandidate']>
    ) => {
      state.selectedCandidate = action.payload
    },
    setSelectedApplicant: (
      state,
      action: PayloadAction<
        JobReducerState['jobDetailPage']['selectedApplicant']
      >
    ) => {
      state.jobDetailPage.selectedApplicant = action.payload
    },
    setApplicationIds: (
      state,
      action: PayloadAction<JobReducerState['jobDetailPage']['applicationIds']>
    ) => {
      state.jobDetailPage.applicationIds = action.payload
    },
    setNextApplicantStatus: (
      state,
      action: PayloadAction<
        JobReducerState['jobDetailPage']['nextApplicantStatus']
      >
    ) => {
      state.jobDetailPage.nextApplicantStatus = action.payload
    },
    setChatIcon: (
      state,
      action: PayloadAction<JobReducerState['jobDetailPage']['chatIcon']>
    ) => {
      state.jobDetailPage.chatIcon = action.payload
    },
    setWhatsappIcon: (
      state,
      action: PayloadAction<JobReducerState['jobDetailPage']['whatsappIcon']>
    ) => {
      state.jobDetailPage.whatsappIcon = action.payload
    },
    setWebProfileCVIcon: (
      state,
      action: PayloadAction<
        JobReducerState['jobDetailPage']['webProfileCVIcon']
      >
    ) => {
      state.jobDetailPage.webProfileCVIcon = action.payload
    },
    setIsPlayerOpen: (
      state,
      action: PayloadAction<JobReducerState['jobDetailPage']['isPlayerOpen']>
    ) => {
      state.jobDetailPage.isPlayerOpen = action.payload
    },
    setIsRecommendedInfoShown: (
      state,
      action: PayloadAction<
        JobReducerState['jobDetailPage']['isRecommendedInfoShown']
      >
    ) => {
      state.jobDetailPage.isRecommendedInfoShown = action.payload
    },
    setSelectedApplicantFilter: (
      state,
      action: PayloadAction<
        JobReducerState['jobDetailPage']['selectedApplicantFilter']
      >
    ) => {
      state.jobDetailPage.selectedApplicantFilter = action.payload
    },
    setApplicantFilterIndex: (
      state,
      action: PayloadAction<
        JobReducerState['jobDetailPage']['applicantFilterIndex']
      >
    ) => {
      state.jobDetailPage.applicantFilterIndex = action.payload
    },
    setApplicantFileURL: (
      state,
      action: PayloadAction<JobReducerState['jobDetailPage']['applicantFile']>
    ) => {
      state.jobDetailPage.applicantFile = action.payload
    },
    setSelectedTabIndex: (
      state,
      action: PayloadAction<
        JobReducerState['jobDetailPage']['selectedTabIndex']
      >
    ) => {
      state.jobDetailPage.selectedTabIndex = action.payload
    },
    setJobDetailDrawerState(
      state,
      action: PayloadAction<Partial<JobReducerState['jobDetailDrawer']>>
    ) {
      state.jobDetailDrawer = {
        ...state.jobDetailDrawer,
        ...action.payload,
      }
    },
    resetJobDetailDrawerState(state) {
      state.jobDetailDrawer = { ...initialState.jobDetailDrawer }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getJobDetail.fulfilled,
      (state, action: PayloadAction<JobReducerState['jobDetail']>) => {
        state.jobDetail = action?.payload
      }
    )
  },
})

export const useJobsActions = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators(jobSlice.actions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}

export const jobActions = jobSlice.actions
export default jobSlice.reducer
