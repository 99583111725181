import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { GENDER_OPTIONS_TYPE } from '@constants/genders'

import { IndustryType } from '@custom-types/candidates'
import { DegreeType } from '@custom-types/minimum-education'

export type ApplicantFilterProps = {
  jobId: string
  workExperienceIndustries: IndustryType[]
  minimumEducation: DegreeType
  selectedWorkExperience: {
    id: string
    name: string
  }
  minWorkEx: number
  maxWorkEx: number
  includeMaxWorkEx: boolean
  includeMinWorkEx: boolean
  minimumAge: number
  maximumAge: number
  gender: GENDER_OPTIONS_TYPE
  onlyPremiumBadge: boolean
}

type StateProps = {
  applicantFilters: Partial<ApplicantFilterProps>[]
  selectedApplicantFilter: ApplicantFilterProps | null
}

const initialState: StateProps = {
  applicantFilters: [],
  selectedApplicantFilter: null,
}

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setApplicantFilters: (
      state,
      action: PayloadAction<StateProps['applicantFilters']>
    ) => {
      state.applicantFilters = action.payload
    },
    setSelectedApplicantFilter: (
      state,
      action: PayloadAction<StateProps['selectedApplicantFilter']>
    ) => {
      state.selectedApplicantFilter = action.payload
    },
    resetApplicantFilterByJobId: (state, action: PayloadAction<string>) => {
      const newArr = [...state.applicantFilters]
      const saf = newArr.findIndex((af) => af.jobId === action.payload)

      if (saf !== -1) {
        newArr.splice(saf, 1)
        state.applicantFilters = newArr
      }
    },
  },
})

export const filterActions = filterSlice.actions

export default filterSlice.reducer
