import amplitude from 'amplitude-js'

export const initAmplitude = () => {
  amplitude.getInstance().init(process.env.NEXT_PUBLIC_AMPLITUDE_ID as string)
}

export const setAmplitudeUserDevice = (deviceId: string) => {
  amplitude.getInstance().setDeviceId(deviceId)
}

export const setAmplitudeUserId = (userId: string) => {
  // eslint-disable-next-line no-console
  console.log(`Amplitude - ${userId}`)
  amplitude.getInstance().setUserId(userId)
}

export const setAmplitudeUserProperties = (
  properties: Record<string, string>
) => {
  // eslint-disable-next-line no-console
  console.log(`Amplitude ${JSON.stringify(properties)}`)
  amplitude.getInstance().setUserProperties(properties)
}

export const setAmplitudeData = (
  eventType: string,
  eventProperties?: unknown
) => {
  amplitude.getInstance().logEvent(eventType, eventProperties)
  // eslint-disable-next-line no-console
  console.log(
    `Amplitude - ${eventType}${
      eventProperties ? ` ${JSON.stringify(eventProperties)}` : ''
    }`
  )
}
