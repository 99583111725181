/* eslint-disable consistent-return */
import { post } from '@helpers/fetch-wrapper'
import { useRouter } from 'next/router'
import React from 'react'

import { PUBLIC_PATH } from '@constants/paths'
import { HEARTBEAT_URL } from '@constants/url'

function useHeartbeat({
  shouldStartHeartbeat,
}: {
  shouldStartHeartbeat: boolean
}) {
  const intervalId = React.useRef<ReturnType<typeof setInterval>>()
  const router = useRouter()

  React.useEffect(() => {
    if (!router.isReady) return
    if (Object.values(PUBLIC_PATH).includes(router.pathname)) return
    if (!shouldStartHeartbeat) return

    intervalId.current = setInterval(() => {
      post(HEARTBEAT_URL)
    }, 5000)
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current)
      }
    }
  }, [shouldStartHeartbeat, router])
}

export default useHeartbeat
