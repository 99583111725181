import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { FilterPSCandidate } from '@features/premium-service/candidate/type'

import { JobPost } from '@custom-types/job-post'

type PremiumServiceState = {
  selectedJob?: Partial<JobPost>
  showChangeJLModal: boolean
  queryCandidate: FilterPSCandidate
  path: string
  detailDrawer: {
    candidateId: string
    showDrawer: boolean
    jobApplicationId: string
  }
}

const initialState: PremiumServiceState = {
  selectedJob: {},
  detailDrawer: {
    candidateId: '',
    showDrawer: false,
    jobApplicationId: '',
  },
  path: 'post-screening-list',
  showChangeJLModal: false,
  queryCandidate: {
    searchText: '',
    page: 1,
  },
}

export const premiumServiceSlice = createSlice({
  name: 'premiumService',
  initialState,
  reducers: {
    setSelectedJob(
      state,
      action: PayloadAction<PremiumServiceState['selectedJob']>
    ) {
      state.selectedJob = action.payload
    },
    setShowChangeJLModal(
      state,
      action: PayloadAction<PremiumServiceState['showChangeJLModal']>
    ) {
      state.showChangeJLModal = action.payload
    },
    setQueryCandidate(
      state,
      action: PayloadAction<PremiumServiceState['queryCandidate']>
    ) {
      state.queryCandidate = {
        ...state.queryCandidate,
        ...action.payload,
      }
    },
    resetQueryCandidate(state) {
      state.queryCandidate = {
        page: 1,
        searchText: '',
      }
    },
    setDetailDrawer(
      state,
      action: PayloadAction<PremiumServiceState['detailDrawer']>
    ) {
      state.detailDrawer = action.payload
    },
    setPath(state, action: PayloadAction<PremiumServiceState['path']>) {
      state.path = action.payload
    },
  },
})

const { actions } = premiumServiceSlice

export const usePremiumServiceActions = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators(actions, dispatch),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}
