import { get } from '@helpers/fetch-wrapper'
import { useRouter } from 'next/router'
import React, { ReactNode, useEffect, useState } from 'react'

import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import { useAppSelector } from '@hooks/redux'

import { ACCESS_CREATED_NEW_ACCOUNT } from '@constants/event-tracking'
import { AUTH_PATH, PUBLIC_PATH } from '@constants/paths'
import { ATMA_EMPLOYER_ACCESS_TOKEN } from '@constants/storage-items'
import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import trackEvents from '@utils/track-events'

type Props = {
  children: ReactNode
}

const AuthGuard: React.FC<Props> = ({ children }: Props) => {
  const router = useRouter()

  const [finishUserAuthCheck, setFinishUserAuthCheck] = useState(false)

  const isOps = useAppSelector((state) => state.profileReducer.isOps)

  const userType = localStorage.getItem('userType')
  const selectedPathName = () => {
    if (userType === 'ps') {
      return '/ps-login'
    }
    if (isOps) {
      return '/mac-login'
    }
    return '/login'
  }

  const authCheck = async (): Promise<boolean> => {
    const accessToken = localStorage.getItem(ATMA_EMPLOYER_ACCESS_TOKEN)

    // Path accessed by url/from searchbar or first load page
    const accessedPathIsPublicPath = Object.values(PUBLIC_PATH).includes(
      router.pathname
    )

    if (!accessedPathIsPublicPath && !accessToken) {
      if (router.query.returnUrl) {
        return router.replace(router.asPath)
      }
      router.replace({
        pathname: selectedPathName(),
        query: { returnUrl: router.asPath },
      })
      localStorage.removeItem('userType')
      return true
    }

    if (accessedPathIsPublicPath && !accessToken) {
      return Promise.resolve(true)
    }

    if (accessToken) {
      const getEmployerProfileResp = await get(EMPLOYERS_PROFILE_URL)

      const newEmployerProfileData: GetEmployerProfileAPIResponseData =
        getEmployerProfileResp.data

      const isUserOnboarded = newEmployerProfileData?.isPhantom
        ? true
        : newEmployerProfileData?.isNewUser === false &&
          Boolean(newEmployerProfileData?.fullName) &&
          Boolean(newEmployerProfileData?.company?.id) &&
          Boolean(newEmployerProfileData?.company?.employee?.designationId)

      if (
        userType === 'ps' &&
        (router.pathname === '/' || router.pathname === '/jobs')
      ) {
        return router.replace('/premium-service/candidate')
      }

      if (newEmployerProfileData?.isNewUser !== undefined) {
        if (AUTH_PATH.includes(router.pathname) && isUserOnboarded) {
          return router.replace({
            pathname:
              userType === 'ps' ? '/premium-service/candidate' : '/jobs',
          })
        }

        if (
          !isUserOnboarded &&
          router.pathname !== '/onboarding/account-info' &&
          router.pathname !== '/onboarding/company-info' &&
          router.pathname !== '/onboarding/review-account'
        ) {
          if (!newEmployerProfileData?.fullName) {
            trackEvents(ACCESS_CREATED_NEW_ACCOUNT)
            return router.replace({
              pathname: '/onboarding/account-info',
            })
          }

          if (
            !newEmployerProfileData?.company?.id ||
            !newEmployerProfileData?.company?.employee?.designationId
          ) {
            return router.replace({
              pathname: '/onboarding/company-info',
            })
          }
        }
        return true
      }
    }

    return router.replace({
      pathname: userType === 'ps' ? '/premium-service/candidate' : '/jobs',
    })
  }

  useEffect(() => {
    // on initial load - run auth check
    async function authCheckRedirection() {
      const finish = await authCheck()
      if (finish) {
        setFinishUserAuthCheck(finish)
      }
    }
    authCheckRedirection()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!finishUserAuthCheck) return null

  return children
}

export default AuthGuard
