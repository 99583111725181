/* eslint-disable import/prefer-default-export */
import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

export type TalentPoolState = {
  selectedCandidateId: string
  showPoolInviteTo:
    | 'BULK_INTERVIEW'
    | 'BULK_APPLY'
    | 'SINGLE_INTERVIEW'
    | 'SINGLE_APPLY'
    | ''
  selectedCandidateIds: number[] | string[] | string
  detailDrawer: {
    isOpen: boolean
    candidateId?: string
  }
  selectedJobId: string
  hasNewPool: boolean
  talentPoolScreen: {
    tab: 'active' | 'archive'
  }
  selectedEditPoolId: string
  poolCount: number
  triggerFrom: string
}

const initialState: TalentPoolState = {
  selectedCandidateId: '',
  selectedCandidateIds: '',
  selectedEditPoolId: '',
  showPoolInviteTo: '',
  hasNewPool: false,
  triggerFrom: '',
  detailDrawer: {
    isOpen: false,
    candidateId: '',
  },
  selectedJobId: '',
  talentPoolScreen: {
    tab: 'active',
  },
  poolCount: 0,
}

export const talentPoolSlice = createSlice({
  name: 'pool',
  initialState,
  reducers: {
    setSelectedCandidateId(
      state,
      action: PayloadAction<TalentPoolState['selectedCandidateId']>
    ) {
      state.selectedCandidateId = action.payload
    },
    setTalentPoolScreenState(
      state,
      action: PayloadAction<TalentPoolState['talentPoolScreen']>
    ) {
      state.talentPoolScreen = {
        ...state.talentPoolScreen,
        ...action.payload,
      }
    },
    setPoolInviteTo(
      state,
      action: PayloadAction<TalentPoolState['showPoolInviteTo']>
    ) {
      state.showPoolInviteTo = action.payload
    },
    setSelectedCandidateIds(
      state,
      action: PayloadAction<TalentPoolState['selectedCandidateIds']>
    ) {
      state.selectedCandidateIds = action.payload
    },
    setSelectedJobId(
      state,
      action: PayloadAction<TalentPoolState['selectedJobId']>
    ) {
      state.selectedJobId = action.payload
    },
    setHasNewPool(state, action: PayloadAction<TalentPoolState['hasNewPool']>) {
      state.hasNewPool = action.payload
    },
    setDetailDrawer(
      state,
      action: PayloadAction<TalentPoolState['detailDrawer']>
    ) {
      state.detailDrawer = action.payload
    },
    setSelectedEditPoolId(
      state,
      action: PayloadAction<TalentPoolState['selectedEditPoolId']>
    ) {
      state.selectedEditPoolId = action.payload
    },
    setPoolCount(state, action: PayloadAction<TalentPoolState['poolCount']>) {
      state.poolCount = action.payload
    },
    setTriggerFrom(
      state,
      action: PayloadAction<TalentPoolState['triggerFrom']>
    ) {
      state.triggerFrom = action.payload
    },
  },
})

const { actions } = talentPoolSlice

export const useTalentPoolActions = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators(actions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}
