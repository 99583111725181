import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: process.env.NEXT_PUBLIC_GTAG_MANAGER_ID as string,
}

const GoogleTagManager = () => {
  useEffect(() => {
    console.log('Google Tag Manager - Initialize')
    TagManager.initialize(tagManagerArgs)
  }, [])

  return null
}

export default GoogleTagManager
