/* eslint-disable import/prefer-default-export */
import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

export type KycState = {
  showCreateNewCompanyModal: boolean
  isFinishedPreKyc: boolean
  showPreKycVerificationModal: boolean
  showKycChecklistModal: boolean
  page: 'basic-info' | 'company-info' | 'additional-info'
}

const initialState: KycState = {
  showCreateNewCompanyModal: false,
  isFinishedPreKyc: false,
  showPreKycVerificationModal: false,
  showKycChecklistModal: false,
  page: 'basic-info',
}

export const kycSlice = createSlice({
  initialState,
  name: 'kyc',
  reducers: {
    setPage: (state, actions: PayloadAction<KycState['page']>) => {
      state.page = actions.payload
    },
    /**
     *
     * @description Toggle create new company modal
     */
    toggleModal(state) {
      state.showCreateNewCompanyModal = !state.showCreateNewCompanyModal
    },
    /**
     * @param boolean
     * @description Set create new company modal open state
     */
    setOpenModal(state, action: PayloadAction<boolean>) {
      state.showCreateNewCompanyModal = action.payload
    },
    setIsFinishedPreKyc(state, action: PayloadAction<boolean>) {
      state.isFinishedPreKyc = action.payload
    },
    togglePreKycVerificationModal(state) {
      state.showPreKycVerificationModal = !state.showPreKycVerificationModal
    },
    setOpenPreKycVerificationModal(state, action: PayloadAction<boolean>) {
      state.showPreKycVerificationModal = action.payload
    },
    setKycChecklistModal(state, action: PayloadAction<boolean>) {
      state.showKycChecklistModal = action.payload
    },
    resetState() {
      return initialState
    },
  },
})

export const { actions: kycActions, reducer: kycReducer } = kycSlice

export const useKycActions = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators(kycActions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}

export default kycSlice
