/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type currentActiveCoachmarkType =
  | 'JOBS_HOME'
  | 'ATS'
  | 'INVITE_CANDIDATE'
  | 'ATS_2'
  | 'NONE'

export type StateProps = {
  coachmarksStatusMap: Omit<Record<currentActiveCoachmarkType, number>, 'NONE'>
  currentActiveCoachmark: currentActiveCoachmarkType
}
const initialState: StateProps = {
  coachmarksStatusMap: {
    JOBS_HOME: 0,
    ATS: 0,
    INVITE_CANDIDATE: 0,
    ATS_2: 0,
  },
  currentActiveCoachmark: 'NONE',
}

const coachmarksSlice = createSlice({
  name: 'coachmarks',
  initialState,
  reducers: {
    saveCoachmarksStatus(
      state,
      action: PayloadAction<Partial<StateProps['coachmarksStatusMap']>>
    ) {
      state.coachmarksStatusMap = {
        ...state.coachmarksStatusMap,
        ...action.payload,
      }
    },
    saveCurrentActiveCoachmark(
      state,
      action: PayloadAction<currentActiveCoachmarkType>
    ) {
      state.currentActiveCoachmark = action.payload
    },
  },
})

export const coachmarksActions = coachmarksSlice.actions

export default coachmarksSlice.reducer
