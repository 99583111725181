/* eslint-disable prefer-destructuring */
import { Store } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import Router from 'next/router'

import { PUBLIC_PATH } from '@constants/paths'
import {
  ATMA_EMPLOYER_ACCESS_TOKEN,
  ATMA_EMPLOYER_REFRESH_TOKEN,
} from '@constants/storage-items'
import { POST_REFRESHTOKEN_URL } from '@constants/url'

// eslint-disable-next-line import/no-cycle
import { RootState } from '@store/index'

const axiosInstance = axios.create({
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})
export const setupInterceptor = (store: Store<RootState>) =>
  axiosInstance.interceptors.response.use(
    (response) =>
      // Return normal response
      response,
    (error: AxiosError) => {
      // Return non auth error

      if (error?.response?.status !== 401) {
        throw error
      }
      const errorMessage = String(error?.response?.data?.message) || ''

      if (
        error?.response?.status === 401 &&
        errorMessage.toLowerCase() !== 'unauthorized'
      ) {
        throw error
      }

      const refreshToken = localStorage.getItem(ATMA_EMPLOYER_REFRESH_TOKEN)
      if (!refreshToken) {
        const accessedPathIsPublicPath = Object.values(PUBLIC_PATH).includes(
          Router.pathname
        )

        if (!accessedPathIsPublicPath) {
          const userType = localStorage.getItem('userType')
          const isOps = store.getState().profileReducer.isOps
          const selectedPathName = () => {
            if (userType === 'ps') {
              return '/ps-login'
            } if (isOps) {
              return '/mac-login'
            } 
              return '/login'
            
          }

          Router.replace({
            pathname: selectedPathName(),
            query: { returnUrl: Router.asPath },
          })
          localStorage.removeItem('userType')
        }

        throw error
      }

      return axiosInstance
        .post(POST_REFRESHTOKEN_URL, { refreshToken })
        .then((response) => {
          localStorage.setItem(
            ATMA_EMPLOYER_ACCESS_TOKEN,
            response?.data?.accessToken
          )
          localStorage.setItem(
            ATMA_EMPLOYER_REFRESH_TOKEN,
            response?.data?.refreshToken
          )
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`
          const { config } = error
          config.headers = {
            Authorization: `Bearer ${response.data.accessToken}`,
          }

          return new Promise((resolve, reject) => {
            axiosInstance
              .request(config)
              .then((retryResponse) => {
                resolve(retryResponse)
              })
              .catch((retryError) => {
                reject(retryError)
              })
          })
        })
        .catch((err) => {
          localStorage.removeItem(ATMA_EMPLOYER_ACCESS_TOKEN)
          localStorage.removeItem(ATMA_EMPLOYER_REFRESH_TOKEN)
          throw err
        })
    }
  )

export default axiosInstance
