import { useRouter } from 'next/router'
import { useEffect } from 'react'

const MetaPixel: React.FC = () => {
  const router = useRouter()
  const META_PIXEL_ID = process.env.NEXT_PUBLIC_META_PIXEL_ID as string

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(META_PIXEL_ID) // facebookPixelId
        ReactPixel.pageView()

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView()
        })
      })

    console.log('Meta Pixel - Initialize')
  }, [router.events, META_PIXEL_ID])

  return null
}

export default MetaPixel
