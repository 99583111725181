import useSWRImmutable from 'swr/immutable'

import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import customFetcher from '@utils/custom-fetcher'

export function useEmployerProfileData() {
  const employerProfileDataQueryResult =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(
      EMPLOYERS_PROFILE_URL,
      customFetcher,
      {
        shouldRetryOnError: false,
      }
    )

  return employerProfileDataQueryResult
}

export default useEmployerProfileData
