/* eslint-disable import/no-cycle */

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as fetchWrapper from '@helpers/fetch-wrapper'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { PostProfileProps, profileActions } from '@features/profile/reducers'

import {
  GET_COMPANIES,
  GET_DESIGNATIONS_URL,
  GET_INDUSTRIES_URL,
  GET_SOCIAL_SOURCES_URL,
  JOB_POSTS_URL_NEW,
} from '@constants/url'

type postCompanyType = {
  companyName: string
  industryId: string
  employee?: {
    designationId?: string
    freeText?: string
  }
}

export type StateProps = {
  page: 'profile' | 'company-profile'
  profileData?: PostProfileProps
  isModalOpen: boolean
}

export const getSocialSources = createAsyncThunk(
  'onboard/getSocialSources',
  async (_, { rejectWithValue }) => {
    try {
      const res = await fetchWrapper.get(GET_SOCIAL_SOURCES_URL)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getDesignations = createAsyncThunk(
  'onboarding/getDesignations',
  async (_, { rejectWithValue }) => {
    try {
      const res = await fetchWrapper.get(GET_DESIGNATIONS_URL)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getIndustries = createAsyncThunk(
  'onboard/getIndustries',
  async (_, { rejectWithValue }) => {
    try {
      const res = await fetchWrapper.get(GET_INDUSTRIES_URL)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getCompanies = createAsyncThunk(
  'onboard/getCompanies',
  async (textInput: string, { rejectWithValue }) => {
    try {
      const res = await fetchWrapper.get(GET_COMPANIES(textInput))
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const postCompany = createAsyncThunk(
  'onboard/postCompanies',
  async (data: postCompanyType, thunkApi) => {
    try {
      const res = await fetchWrapper.post(JOB_POSTS_URL_NEW, data)

      await thunkApi.dispatch(profileActions.setCompany(res.data))
      return res.data
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }
)

const initialState: StateProps = {
  page: 'profile',
  profileData: undefined,
  isModalOpen: false,
}

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<StateProps['page']>) {
      state.page = action.payload
    },
    saveLocalSubmitData(state, action: PayloadAction<PostProfileProps>) {
      state.profileData = action.payload
    },
    setIsModalOpen(state, action: PayloadAction<StateProps['isModalOpen']>) {
      state.isModalOpen = action.payload
    },
  },
})

export const onboardingActions = onboardingSlice.actions

export default onboardingSlice.reducer
