export const setAppsFlyerUserId = (userId: string) => {
  console.log(`AppsFlyer - ${userId}`)
  AF('pba', 'setCustomerUserId', userId)
}

export const setAppsFlyerData = (eventName: string, eventValue: unknown) => {
  console.log(
    `AppsFlyer - ${eventName}${
      eventValue ? ` ${JSON.stringify(eventValue)}` : ''
    }`
  )
  AF('pba', 'event', {
    eventType: 'EVENT',
    eventName,
    eventValue,
  })
}
