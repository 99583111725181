import { setAmplitudeData, setAmplitudeUserId } from './amplitude'
import { setAppsFlyerData, setAppsFlyerUserId } from './appsflyer'

export default function trackEvents(events: string, values?: unknown) {
  setAmplitudeData(events, values)
  setAppsFlyerData(events, values)
  // setGtmData(events, values)

  // if (events === ACCESS_CREATED_NEW_ACCOUNT) {
  // 	window?.lintrk('track', {
  // 		conversion_id: 10595113,
  // 	})
  // }
}

export const setUserId = (userId: string) => {
  setAmplitudeUserId(userId)
  setAppsFlyerUserId(userId)
}
