/* eslint-disable import/no-cycle */
import {
  Stringifiable,
  StringifiableRecord,
  StringifyOptions,
  stringifyUrl,
} from 'query-string'

import { QueryPScandidate } from '@features/premium-service/candidate/type'

import { InvitationTemplateQuery } from '@custom-types/candidates'
import { PostInterviewInviteRequest } from '@custom-types/requests'

// import { ApplicationStatus, InterviewResultStatus, InviteStatus } from '@features/chats/types'

const stringifyOptions: StringifyOptions = {
  skipNull: true,
  skipEmptyString: true,
  arrayFormat: 'bracket',
}

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
const ROOT_URL = process.env.NEXT_PUBLIC_BASE_JP_WEB_ROOT_API_URL as string
const MOCK_URL = process.env.NEXT_PUBLIC_MOCK_URL as string
const WEB_PROFILE_URL = process.env.NEXT_PUBLIC_WEB_PROFILE_URL as string
const PS_ROOT_URL = process.env.NEXT_PUBLIC_PS_ROOT_URL as string
// *** MISC URL
const TNC_URL = 'https://www.atma.co.id/business-terms-of-service' as const
const PRIVACY_URL = 'https://www.atma.co.id/privacy-policy' as const
const ONBOARD_VIDEO_URL = 'https://www.youtube.com/watch?v=gRSBks_UkI4' as const
const PASANG_LOKER_POST_DATA_URL =
  'https://api.airtable.com/v0/appKPWIwQdNp4ajud/Website%20Leads' as const

const HEARTBEAT_URL = `${ROOT_URL}/heartbeat`

// *** AUTHENTICATION URL
const AUTH_URL = `${ROOT_URL}/auth` as const
const PS_AUTH_URL = `${PS_ROOT_URL}/auth` as const
const GENERATE_OTP_URL = `${AUTH_URL}/generate-otp` as const
const GENERATE_PS_OTP_URL = `${PS_AUTH_URL}/generate-otp` as const
const VERIFY_OTP_URL = `${AUTH_URL}/verify-otp` as const
const VERIFY_PS_OTP_URL = `${PS_AUTH_URL}/verify-otp` as const
const POST_REFRESHTOKEN_URL = `${AUTH_URL}/refresh-token` as const

// *** EMPLOYERS URL
const EMPLOYERS_URL = `${ROOT_URL}/employers` as const
const EMPLOYERS_PROFILE_URL = `${EMPLOYERS_URL}/profile` as const
const EMPLOYERS_UPLOAD_IMG_PROFILE_URL =
  `${EMPLOYERS_URL}/upload-profile-image` as const

// *** JOBS URL
const JOB_POSTS_URL = `${ROOT_URL}/job-posts` as const
const JOB_POSTS_URL_NEW = `${ROOT_URL}/companies` as const
const JOB_DUPLICATE_EXPIRED_JOB_URL = (id: string) =>
  `${JOB_POSTS_URL}/${id}/duplicate` as const
const JOB_POSTS_BYID_URL = (id: string) => `${JOB_POSTS_URL}/${id}`
const JOB_LISTING_URL = (
  id: string,
  query: Record<PropertyKey, Stringifiable>
) =>
  stringifyUrl({
    url: `${JOB_POSTS_URL_NEW}/${id}/job-posts`,
    query,
  })

const JOB_DETAIL_URL = (id: string) => `${JOB_POSTS_URL}/${id}` as const
const JOB_STATUS_SUMMARY_URL = (jobId: string) =>
  `${ROOT_URL}/job-posts/${jobId}/applications/status-summary` as const
const JOB_CANDIDATES_BYID_URL = (
  jobId: string,
  // eslint-disable-next-line default-param-last
  status = 'In Review',
  searchText: string
) =>
  `${MOCK_URL}/job-posts/${jobId}/applicants?status=${status}search-text=${searchText}` as const
const ALL_JOBPOSTS_BY_COMPANY_URL = (companyId: string) =>
  `${ROOT_URL}/companies/${companyId}/job-posts/all` as const
const CREATE_JOB_APPLICATION_URL = (jobPostId: string) =>
  `${ROOT_URL}/job-posts/${jobPostId}/applications`
const JOB_APPLICANT_WORK_EXP_INDUSTRIES_URL = (
  jobId: string,
  status: string
) => {
  const url = `${ROOT_URL}/job-posts/${jobId}/applicants/work-experience-industries`
  return stringifyUrl(
    {
      url,
      query: {
        status,
      },
    },
    stringifyOptions
  )
}
const UPLOAD_MEDIA_JOB_POST_URL = `${ROOT_URL}/job-posts/upload-media`

type CandidateByStatusUrlParamsType = {
  status: string
  page: number
  workExperienceIndustries: string
  minWorkEx: number
  maxWorkEx: number
  includeMinWorkEx: boolean
  includeMaxWorkEx: boolean
  minimumEducation: string
  gender: string
  minimumAge: number
  maximumAge: number
  onlyPremiumBadge: boolean
  lastId?: string
  'search-text'?: string
}

// *** CANDIDATES URL
const CANDIDATE_BYID_URL = (candidateId: string) =>
  `${ROOT_URL}/candidates/${candidateId}`

const CANDIDATE_BYID_URL_REPORT = (candidateId: string) =>
  `${ROOT_URL}/candidates/${candidateId}/report`

const CANDIDATE_BYID_URL_BLOCK = (candidateId: string) =>
  `${ROOT_URL}/candidates/${candidateId}/block`

const CANDIDATE_CREDENTIAL_BYID = (candidateId: string, jobPostId: string) =>
  `${ROOT_URL}/candidates/${candidateId}/credentials?jobPostId=${jobPostId}`

const CANDIDATE_BY_STATUS_URL = (
  jobId: string,
  query: Partial<CandidateByStatusUrlParamsType>
) =>
  stringifyUrl(
    {
      url: `${ROOT_URL}/job-posts/${jobId}/applicants`,
      query,
    },
    stringifyOptions
  )

const CANDIDATE_APPLICATION_STATUS_URL = (
  jobId: string,
  applicationId: string,
  status: string
) =>
  `${ROOT_URL}/job-posts/${jobId}/applications/${applicationId}/status?status=${status}` as const
const BULK_CANDIDATE_APPLICATION_STATUS_URL = (jobId: string, status: string) =>
  `${ROOT_URL}/job-posts/${jobId}/applications/status?status=${status}` as const

type RNAQueries = Pick<CandidateByStatusUrlParamsType, 'lastId' | 'page'>
const RECOMMENDED_NONAPPLICANTS_URL = (jobId: string, query: RNAQueries) =>
  stringifyUrl(
    {
      url: `${ROOT_URL}/job-posts/${jobId}/recommended-candidates`,
      query,
    },
    stringifyOptions
  )
const INVITE_CANDIDATE_APPLY_URL = (jobId: string, candidateId: string) =>
  `${ROOT_URL}/job-posts/${jobId}/invite-candidates/${candidateId}` as const

const WEB_PROFILE_CANDIDATE_URL = (candidateSlug: string) =>
  `${WEB_PROFILE_URL}/kandidat/${candidateSlug}`

const CANDIDATE_ATMA_ID = (candidateId: string) =>
  `${ROOT_URL}/candidates/${candidateId}/view-cv`

// *** COMPANY URL
const COMPANY_URL = `${ROOT_URL}/companies` as const
const PJP_COMPANY_URL = (query: Record<PropertyKey, Stringifiable>) =>
  stringifyUrl(
    { url: `${COMPANY_URL}/phantom-companies`, query },
    stringifyOptions
  )
const SWITCH_COMPANY_URL = (companyId: string) =>
  `${COMPANY_URL}/${companyId}/switch`
const COMPANY_BYID_URL = (id: string) => `${COMPANY_URL}/${id}` as const
const POST_COMPANY_LOGO_URL = (id: string) =>
  `${COMPANY_URL}/${id}/logo` as const
const COMPANY_CONTACT_URL = (id: string) =>
  `${COMPANY_BYID_URL(id)}/contact` as const
const COMPANY_SEARCH_BY_NAME = (name: string) =>
  stringifyUrl({
    url: `${COMPANY_URL}/search-by-name`,
    query: {
      searchText: name,
    },
  })
// *** CHAT URL
const SAVE_CHANNEL_URL = (jobApplicationId: string) =>
  `${ROOT_URL}/chat/job-applications/${jobApplicationId}` as const
const START_CHAT_URL = `${ROOT_URL}/chat/channels`
const GET_CHANNEL_BY_ID_URL = (channelId: string) =>
  `${ROOT_URL}/chat/job-post-channels/${channelId}` as const
const GET_CHANNELS_URL = (query: Record<PropertyKey, Stringifiable>) =>
  stringifyUrl(
    {
      url: `${ROOT_URL}/chat/job-post-channels`,
      query,
    },
    stringifyOptions
  )
const GET_INVITATION_TEMPLATE = (query?: InvitationTemplateQuery) =>
  stringifyUrl({
    url: `${ROOT_URL}/chat/templates`,
    query,
  })
const COMPANY_JOB_APPLICATION_STATUS_SUMMARY_URL = ({
  jobPostId,
  companyId,
}: {
  jobPostId?: string
  companyId: string
}) =>
  stringifyUrl(
    {
      url: `${ROOT_URL}/companies/${companyId}/job-applications/status-summary`,
      ...(jobPostId && {
        query: { jobPostId },
      }),
    },
    stringifyOptions
  )
const INVITE_CANDIDATE = `${ROOT_URL}/chat/job-post-channels/` as const
const ACCEPT_CANDIDATE_URL = (channelId: string) =>
  `${ROOT_URL}/chat/job-post-channels/${channelId}/accept`

const REJECT_CANDIDATE_URL = (channelId: string) =>
  `${ROOT_URL}/chat/job-post-channels/${channelId}/reject`

const SHORTLIST_CANDIDATE_URL = (channelId: string) =>
  `${ROOT_URL}/chat/job-post-channels/${channelId}/shortlist`

const SEARCH_CANDIDATE_TEMPLATES_URL = (
  query: Record<PropertyKey, Stringifiable>
) =>
  stringifyUrl(
    {
      url: `${ROOT_URL}/chat/templates`,
      query,
    },
    stringifyOptions
  )

const CREATE_CHANNEL_URL = `${ROOT_URL}/chat/job-post-channels`

const POST_CANDIDATE_TEMPLATE_URL = `${ROOT_URL}/chat/templates`
const UPDATE_CANDIDATE_TEMPLATE_URL = (templateId: string) =>
  `${ROOT_URL}/chat/templates/${templateId}`
// *** CANDIDATE PORTAL URL
const CANDIDATES_PORTAL_URL = (
  page = 1,
  jobProfileIds = '',
  minDegreeId = '',
  minWorkEx: number | string = '',
  maxWorkEx: number | string = '',
  includeMaxWorkEx: boolean | string = '',
  includeMinWorkEx: boolean | string = '',
  latitude: number | string = '',
  longitude: number | string = '',
  lastId = ''
) => {
  const query = {
    page,
    jobProfileIds,
    minDegreeId,
    minWorkEx,
    maxWorkEx,
    includeMaxWorkEx,
    includeMinWorkEx,
    latitude,
    longitude,
    lastId,
  }

  return stringifyUrl(
    {
      url: `${ROOT_URL}/candidates`,
      query,
    },
    stringifyOptions
  )
}

// *** COMMON URL
const COMMON_URL = `${ROOT_URL}/common` as const
const GET_INDUSTRIES_URL = `${COMMON_URL}/industries` as const
const GET_DEGREES_URL = `${COMMON_URL}/degrees` as const
const GET_DOCUMENTS_URL = `${COMMON_URL}/documents` as const
const GET_EQUIPMENTS_URL = `${COMMON_URL}/equipments` as const
const GET_SOCIAL_SOURCES_URL = `${COMMON_URL}/sources` as const
const GET_DESIGNATIONS_URL = `${COMMON_URL}/designations` as const
const GET_JOBPROFILES_URL = `${COMMON_URL}/job-profiles` as const
const GET_JOBROLES_URL = (search: string) =>
  `${COMMON_URL}/job-posts/title/search?search=${search}` as const
const GET_SKILLS_URL = (search: string) =>
  `${COMMON_URL}/skills/search?search=${search}` as const
const GET_SKILL_TAGS_SEARCH_URL = (search: string) =>
  `${COMMON_URL}/skill-tags/search?search=${search}&limit=10` as const

const GET_POST_TAGS_SEARCH_URL = (query: {
  keyWord?: string
  searchText?: string
}) =>
  stringifyUrl(
    {
      url: `${COMMON_URL}/job-post-tags`,
      query,
    },
    stringifyOptions
  )

const GET_SKILL_TAGS_LIST = `${COMMON_URL}/skill-tags` as const
const BENEFIT_TAGS = `${COMMON_URL}/benefit-tags` as const

const CITY_OPTION = (query: { searchText?: string }) =>
  stringifyUrl(
    {
      url: `${COMMON_URL}/filter-option-cities`,
      query,
    },
    stringifyOptions
  )

const INDUSTRY_OPTION_URL = (query: { searchText?: string }) =>
  stringifyUrl(
    {
      url: `${COMMON_URL}/industries`,
      query,
    },
    stringifyOptions
  )

// *** Event
const SEND_EVENT = (eventId: string) =>
  `${ROOT_URL}/events/${eventId}/invites` as const

const SEND_EVENT_RESULT = (eventId: string) =>
  `${ROOT_URL}/event-invites/${eventId}/feedback` as const

const HIRE_CANDIDATE = (eventId: string) =>
  `${ROOT_URL}/event-invites/${eventId}/hired` as const

const EVENTS_BY_CHANNEL_ID = (pageIndex: number, channelId: string) =>
  stringifyUrl(
    {
      url: `${ROOT_URL}/chat/job-post-channels/${channelId}/invitable-events`,
      query: {
        page: pageIndex,
      },
    },
    stringifyOptions
  )

// *** Macc Dashboard
const JOB_APPLICANTS_SUMMARY_URL = (jobId: string) =>
  `${ROOT_URL}/job-posts/${jobId}/summary`

const JOB_POSTS_BY_COMPANY_URL = (companyId: string) =>
  `${ROOT_URL}/companies/${companyId}/job-posts`

const PAGINATED_JOBPOST_BY_COMPANY_URL = (
  companyId: string,
  query: Record<PropertyKey, Stringifiable>
) =>
  stringifyUrl(
    {
      url: JOB_POSTS_BY_COMPANY_URL(companyId),
      query,
    },
    stringifyOptions
  )
const JOB_APPLICANTS_URL = (query: StringifiableRecord) =>
  stringifyUrl(
    {
      url: `${ROOT_URL}/chat/job-post-channels/pre-screening-list`,
      query,
    },
    stringifyOptions
  )

const JOB_POST_SUMMARY = (companyId: string) =>
  `${ROOT_URL}/companies/${companyId}/job-posts/summary`
const GET_COMPANIES = (text: string) =>
  `${ROOT_URL}/companies/search-by-name?searchText=${text}` as const
const CANDIDATE_DETAIL_URL = (candidateId: string) =>
  `${ROOT_URL}/candidates/${candidateId}`
const BULK_FAVOURITE_CANDIDATES_URL = (jobPostId: string) =>
  `${ROOT_URL}/job-posts/${jobPostId}/candidates/bulk-favourite`
const BULK_UNFAVOURITE_CANDIDATES_URL = (jobPostId: string) =>
  `${ROOT_URL}/job-posts/${jobPostId}/candidates/bulk-un-favourite`
const BULK_FEEDBACK_CANDIDATES_URL = (jobPostId: string) =>
  `${ROOT_URL}/job-posts/${jobPostId}/candidates/bulk-feedback`

const TRACK_URL = `${ROOT_URL}/track`

// *** COMMENT URL
const COMMENTS_URL = (jobPostId: string, page: number) =>
  `${ROOT_URL}/job-posts/${jobPostId}/comments?page=${page}`
const CHILD_COMMENTS_URL = (
  jobPostId: string,
  parentCommenId: string,
  page: number
) =>
  `${ROOT_URL}/job-posts/${jobPostId}/comments/${parentCommenId}/child-comments?page=${page}`
const POST_COMMENTS_URL = (jobPostId: string) =>
  `${ROOT_URL}/job-posts/${jobPostId}/comments`
const DELETE_COMMENTS_URL = (jobPostId: string, commentId: string) =>
  `${ROOT_URL}/job-posts/${jobPostId}/comments/${commentId}`

// *** KYC URL
const EMPLOYERS_KYC_ATTEMPTS_URL = `${EMPLOYERS_URL}/kyc-attempts`
const COMPANY_KYC_ATTEMPTS_URL = (companyId: string) =>
  `${COMPANY_BYID_URL(companyId)}/kyc-attempts`
const GET_KYC_DOCUMENT_TYPES = `${ROOT_URL}/initialize` as const
const INITIALIZE_URL = `${ROOT_URL}/initialize` as const
const UPLOAD_KYC_DOCUMENT = `${EMPLOYERS_URL}/upload-kyc-document`
const UPLOAD_COMPANY_KYC_DOCUMENT = (companyId: string) =>
  `${COMPANY_BYID_URL(companyId)}/upload-logo`

// *** INTERVIEW URL
const INTERVIEWS_URL = (page: number) => `${ROOT_URL}/events?page=${page}`
const INTERVIEW_DETAIL_URL = (id: string) => `${ROOT_URL}/events/${id}`
const DESIGNATION_OPTION = (query: { searchText?: string }) =>
  stringifyUrl(
    {
      url: `${COMMON_URL}/filter-option-designations`,
      query,
    },
    stringifyOptions
  )

const JOB_CATEGORIES = (query: { searchText?: string }) =>
  stringifyUrl(
    {
      url: `${COMMON_URL}/job-categories`,
      query,
    },
    stringifyOptions
  )

const GET_JOBPROFILES_V2 = (query: {
  designationId?: string
  jobCategoryId?: string
  searchText?: string
}) =>
  stringifyUrl(
    {
      url: `${COMMON_URL}//job-profiles/v2`,
      query,
    },
    stringifyOptions
  )

const INTERVIEW_INVITES_URL = (
  eventId: string,
  query: { page: number; inviteStatus?: string; jobPostId?: string }
) =>
  stringifyUrl(
    {
      url: `${ROOT_URL}/events/${eventId}/invites`,
      query,
    },
    stringifyOptions
  )

const EVENT_BY_ID_URL = (eventId: string) =>
  stringifyUrl(
    {
      url: `${ROOT_URL}/events/${eventId}`,
    },
    stringifyOptions
  )

const EVENT_BY_COMPANY_ID_URL = (companyId: string, page: number) =>
  stringifyUrl(
    {
      url: `${ROOT_URL}/events/companies/${companyId}/job-posts`,
      query: {
        page,
      },
    },
    stringifyOptions
  )

const EVENTS_URL = (pageIndex: number) =>
  stringifyUrl(
    {
      url: `${ROOT_URL}/events`,
      query: {
        page: pageIndex,
      },
    },
    stringifyOptions
  )

const ATTACH_JOB_POST_TO_EVENT_URL = (eventId: string, jobPostId: string) =>
  `${ROOT_URL}/events/${eventId}/job-posts/${jobPostId}/attach`

const CREATE_EVENT = `${ROOT_URL}/events` as const

const EVENT_INVITES_URL = (data: PostInterviewInviteRequest) =>
  stringifyUrl(
    {
      url: `${ROOT_URL}/events/invites`,
      query: data,
    },
    stringifyOptions
  )

const EVENT_GET_JOB_POST = (eventId: string) =>
  `${ROOT_URL}/events/${eventId}/invites/job-posts`

// *** BOOST URL
const OUTREACH_BOOST_URL = (query: Record<PropertyKey, Stringifiable>) =>
  stringifyUrl({
    url: `${ROOT_URL}/outreach-boost`,
    query,
  })

const OUTREACH_BOOST_RECIPIENT_OPTIONS_URL = (query: StringifiableRecord) =>
  stringifyUrl({
    url: `${ROOT_URL}/outreach-boost/recipient-options`,
    query,
  })

// *** COINS URL
const COINS_URL = `${ROOT_URL}/coins` as const
const COINS_BALANCE_URL = `${COINS_URL}/balance` as const
const PRICE_LIST_URL = `${COINS_URL}/products` as const
const TRANSACTION_HISTORY_URL = (query: Record<PropertyKey, Stringifiable>) =>
  stringifyUrl({ url: `${COINS_URL}/transactions`, query })

const COINS_EXPIRED_LIST_URL = (query: StringifiableRecord) =>
  stringifyUrl({
    url: `${COINS_URL}/expiring`,
    query,
  })

const TRANSACTION_DETAIL_URL = (transactionId: string) =>
  `${COINS_URL}/transactions/${transactionId}`
const COINS_CHARGEABLE_URL = (query: StringifiableRecord) =>
  stringifyUrl({ url: `${COINS_URL}/products/check-chargeable`, query })
const VALIDATE_VOUHER_CODE_URL =
  `${COINS_URL}/vouchers/validate-by-code` as const
const REDEEM_VOUCHER_CODE_URL = `${COINS_URL}/vouchers/redeem-by-code` as const

// *** PAYMENTS URL
const TOPUP_PACKAGES_URL = `${ROOT_URL}/payments/packages`
const TOPUP_PAYMENT_URL = `${ROOT_URL}/payments/top-up`
const TOPUP_PAYMENT_TRANSACTION_DETAIL_URL = (transactionId: string) =>
  `${ROOT_URL}/payments/transactions/${transactionId}/coin-transaction`

// *** TALENT POOL URL
const TALENT_POOL_URL = `${ROOT_URL}/talent-pools`
const GET_TALENT_POOL_LIST_URL = (query: Record<PropertyKey, Stringifiable>) =>
  stringifyUrl(
    {
      url: TALENT_POOL_URL,
      query,
    },
    stringifyOptions
  )

const GET_TALENT_POOL_CANDIDATE_LIST = (
  talentPoolId: string,
  query: Record<PropertyKey, Stringifiable>
) =>
  stringifyUrl(
    {
      url: `${TALENT_POOL_URL}/${talentPoolId}/candidates`,
      query,
    },
    stringifyOptions
  )

const TALENT_POOL_BY_ID_URL = (talentPoolId: string) =>
  `${TALENT_POOL_URL}/${talentPoolId}`
const CHECK_ELIGIBEL_INVITE = `${TALENT_POOL_URL}/check-bulk-invite-eligibility`
const BULK_INVITE = `${TALENT_POOL_URL}/bulk-invite`
const DELETE_CANDIDATES_BY_TALENT_POOL_ID = (talentPoolId: string) =>
  `${TALENT_POOL_BY_ID_URL(talentPoolId)}/bulk-remove-candidates`
const TALENT_POOL_CANDIDATES = `${TALENT_POOL_URL}/candidates`
const TALENT_POOL_BULK_CANDIDATE = (poolId: string) =>
  `${TALENT_POOL_BY_ID_URL(poolId)}/bulk-update-candidates`
const UNARCHIVE_TALENT_POOL = (poolId: string) =>
  `${TALENT_POOL_BY_ID_URL(poolId)}/unarchive`
const CHECK_ELIGIBEL_INVITE_BY_POOL_ID = (poolId: string) =>
  `${TALENT_POOL_BY_ID_URL(poolId)}/check-bulk-invite-eligibility`

// Premium service

const PS_CANDIDATE_LIST = (
  jobPostId: string,
  query: QueryPScandidate,
  path: string = 'post-screening-list'
) =>
  stringifyUrl(
    {
      url: `${PS_ROOT_URL}/job-posts/${jobPostId}/${path}`,
      query,
    },
    stringifyOptions
  )

const PS_REJECT_CANDIDATE = (jobApplicationId: string) =>
  `${PS_ROOT_URL}/job-posts/job-applications/${jobApplicationId}/reject`

const PS_ACCEPT_CANDIDATE = (jobApplicationId: string) =>
  `${PS_ROOT_URL}/job-posts/job-applications/${jobApplicationId}/accept`

const PS_REVOKE_CANDIDATE = (jobApplicationId: string) =>
  `${PS_ROOT_URL}/job-posts/job-applications/${jobApplicationId}/revoke`

const PS_APPLICATION_COUNT = (jobPostId: string) =>
  `${PS_ROOT_URL}/job-posts/${jobPostId}/count-summary`

export {
  // *** Public url
  TNC_URL,
  PRIVACY_URL,
  ONBOARD_VIDEO_URL,
  // *** Authentication url
  GENERATE_OTP_URL,
  GENERATE_PS_OTP_URL,
  VERIFY_OTP_URL,
  VERIFY_PS_OTP_URL,
  // *** Common url
  GET_INDUSTRIES_URL,
  EMPLOYERS_PROFILE_URL,
  GET_DEGREES_URL,
  GET_JOBROLES_URL,
  GET_EQUIPMENTS_URL,
  GET_DOCUMENTS_URL,
  POST_REFRESHTOKEN_URL,
  GET_SKILLS_URL,
  GET_SKILL_TAGS_SEARCH_URL,
  GET_SOCIAL_SOURCES_URL,
  GET_DESIGNATIONS_URL,
  GET_JOBPROFILES_URL,
  COMPANY_URL,
  COMPANY_SEARCH_BY_NAME,
  GET_KYC_DOCUMENT_TYPES,
  // *** Job url
  JOB_POSTS_URL,
  COMPANY_BYID_URL,
  COMPANY_CONTACT_URL,
  POST_COMPANY_LOGO_URL,
  // *** Employer URL
  EMPLOYERS_URL,
  EMPLOYERS_UPLOAD_IMG_PROFILE_URL,
  JOB_LISTING_URL,
  JOB_DETAIL_URL,
  JOB_CANDIDATES_BYID_URL,
  JOB_STATUS_SUMMARY_URL,
  ALL_JOBPOSTS_BY_COMPANY_URL,
  BULK_CANDIDATE_APPLICATION_STATUS_URL,
  CREATE_JOB_APPLICATION_URL,
  JOB_APPLICANT_WORK_EXP_INDUSTRIES_URL,
  JOB_POST_SUMMARY,
  UPLOAD_MEDIA_JOB_POST_URL,
  // *** Candidate url
  CANDIDATE_BYID_URL,
  CANDIDATE_APPLICATION_STATUS_URL,
  RECOMMENDED_NONAPPLICANTS_URL,
  CANDIDATE_BY_STATUS_URL,
  JOB_POSTS_BYID_URL,
  JOB_DUPLICATE_EXPIRED_JOB_URL,
  INVITE_CANDIDATE_APPLY_URL,
  WEB_PROFILE_CANDIDATE_URL,
  CANDIDATE_BYID_URL_REPORT,
  CANDIDATE_BYID_URL_BLOCK,
  // *** Candidate portal url,
  CANDIDATES_PORTAL_URL,
  // *** Chat url
  GET_CHANNELS_URL,
  SAVE_CHANNEL_URL,
  GET_CHANNEL_BY_ID_URL,
  REJECT_CANDIDATE_URL,
  ACCEPT_CANDIDATE_URL,
  COMPANY_JOB_APPLICATION_STATUS_SUMMARY_URL,
  SHORTLIST_CANDIDATE_URL,
  START_CHAT_URL,
  PASANG_LOKER_POST_DATA_URL,
  HIRE_CANDIDATE,
  GET_INVITATION_TEMPLATE,
  // *** Macc Dashboard
  JOB_APPLICANTS_SUMMARY_URL,
  PAGINATED_JOBPOST_BY_COMPANY_URL,
  JOB_APPLICANTS_URL,
  CANDIDATE_DETAIL_URL,
  BULK_FAVOURITE_CANDIDATES_URL,
  BULK_UNFAVOURITE_CANDIDATES_URL,
  BULK_FEEDBACK_CANDIDATES_URL,
  GET_SKILL_TAGS_LIST,
  TRACK_URL,
  SEARCH_CANDIDATE_TEMPLATES_URL,
  POST_CANDIDATE_TEMPLATE_URL,
  UPDATE_CANDIDATE_TEMPLATE_URL,
  CREATE_CHANNEL_URL,
  // *** Comments URL
  COMMENTS_URL,
  CHILD_COMMENTS_URL,
  POST_COMMENTS_URL,
  DELETE_COMMENTS_URL,
  // *** Company url
  GET_COMPANIES,
  JOB_POSTS_URL_NEW,
  PJP_COMPANY_URL,
  SWITCH_COMPANY_URL,
  // *** KYC URL,
  EMPLOYERS_KYC_ATTEMPTS_URL,
  COMPANY_KYC_ATTEMPTS_URL,
  UPLOAD_KYC_DOCUMENT,
  UPLOAD_COMPANY_KYC_DOCUMENT,
  // *** INTERVIEW URL,
  INTERVIEWS_URL,
  EVENTS_BY_CHANNEL_ID,
  SEND_EVENT,
  SEND_EVENT_RESULT,
  INTERVIEW_DETAIL_URL,
  INTERVIEW_INVITES_URL,
  EVENT_BY_ID_URL,
  EVENT_BY_COMPANY_ID_URL,
  EVENTS_URL,
  ATTACH_JOB_POST_TO_EVENT_URL,
  CREATE_EVENT,
  EVENT_INVITES_URL,
  INITIALIZE_URL,
  EVENT_GET_JOB_POST,
  CITY_OPTION,
  DESIGNATION_OPTION,
  INDUSTRY_OPTION_URL,
  INVITE_CANDIDATE,
  JOB_POSTS_BY_COMPANY_URL,
  // *** BOOST URL,
  OUTREACH_BOOST_URL,
  OUTREACH_BOOST_RECIPIENT_OPTIONS_URL,
  JOB_CATEGORIES,
  GET_JOBPROFILES_V2,
  GET_POST_TAGS_SEARCH_URL,
  CANDIDATE_ATMA_ID,
  BENEFIT_TAGS,
  // *** COINS URL,
  COINS_BALANCE_URL,
  PRICE_LIST_URL,
  TRANSACTION_HISTORY_URL,
  TRANSACTION_DETAIL_URL,
  HEARTBEAT_URL,
  COINS_CHARGEABLE_URL,
  VALIDATE_VOUHER_CODE_URL,
  REDEEM_VOUCHER_CODE_URL,
  COINS_EXPIRED_LIST_URL,
  // *** PAYMENTS URL,
  TOPUP_PACKAGES_URL,
  TOPUP_PAYMENT_URL,
  TOPUP_PAYMENT_TRANSACTION_DETAIL_URL,
  CANDIDATE_CREDENTIAL_BYID,
  // *** TALENT POOL URL
  TALENT_POOL_URL,
  GET_TALENT_POOL_LIST_URL,
  GET_TALENT_POOL_CANDIDATE_LIST,
  TALENT_POOL_BY_ID_URL,
  CHECK_ELIGIBEL_INVITE,
  DELETE_CANDIDATES_BY_TALENT_POOL_ID,
  TALENT_POOL_CANDIDATES,
  TALENT_POOL_BULK_CANDIDATE,
  BULK_INVITE,
  UNARCHIVE_TALENT_POOL,
  CHECK_ELIGIBEL_INVITE_BY_POOL_ID,
  // Premium service,
  PS_CANDIDATE_LIST,
  PS_REJECT_CANDIDATE,
  PS_ACCEPT_CANDIDATE,
  PS_REVOKE_CANDIDATE,
  PS_APPLICATION_COUNT,
}
