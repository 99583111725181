/* eslint-disable import/no-cycle */
import { get, post } from '@helpers/fetch-wrapper'
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  GET_DEGREES_URL,
  GET_DOCUMENTS_URL,
  GET_EQUIPMENTS_URL,
  GET_JOBROLES_URL,
  GET_SKILL_TAGS_SEARCH_URL,
  JOB_POSTS_URL,
} from '@constants/url'

export type StateProps = {
  page:
    | 'basic-info'
    | 'candidate-requirements'
    | 'review'
    | 'select-assessment'
    | 'supporting-visual-media'
}

const initialState: StateProps = {
  page: 'basic-info',
}

export const getJobRolesWithInput = createAsyncThunk(
  'create-job/getJobRoles',
  async (inputValue: string, { rejectWithValue }) => {
    try {
      const res = await get(GET_JOBROLES_URL(inputValue))
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getSkillsWithInput = createAsyncThunk(
  'create-job/getSkills',
  async (inputValue: string, { rejectWithValue }) => {
    try {
      const res = await get(GET_SKILL_TAGS_SEARCH_URL(inputValue))
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getDegrees = createAsyncThunk(
  'create-job/getDegress',
  async (_, { rejectWithValue }) => {
    try {
      const res = await get(GET_DEGREES_URL)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getDocuments = createAsyncThunk(
  'create-job/getDocuments',
  async (_, { rejectWithValue }) => {
    try {
      const res = await get(GET_DOCUMENTS_URL)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getEquipments = createAsyncThunk(
  'create-job/getEquipments',
  async (_, { rejectWithValue }) => {
    try {
      const res = await get(GET_EQUIPMENTS_URL)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const postCreateJob = createAsyncThunk(
  'create-job/postCreateJob',
  async (data: Record<PropertyKey, unknown>, { rejectWithValue }) => {
    try {
      const res = await post(JOB_POSTS_URL, data)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const createJobSlice = createSlice({
  name: 'create-job',
  initialState,
  reducers: {
    setPage: (state, actions: PayloadAction<StateProps['page']>) => {
      state.page = actions.payload
    },
    resetState: (state) => {
      state.page = 'basic-info'
    },
  },
})

export const createJobActions = createJobSlice.actions

export default createJobSlice.reducer
