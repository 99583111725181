// eslint-disable-next-line import/no-cycle
import { _delete, patch, post } from '@helpers/fetch-wrapper'
import {
  PayloadAction,
  bindActionCreators,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import {
  CANDIDATE_BYID_URL_BLOCK,
  CANDIDATE_BYID_URL_REPORT,
  CREATE_EVENT,
  DELETE_COMMENTS_URL,
  EVENT_BY_ID_URL,
  GET_CHANNEL_BY_ID_URL,
  HIRE_CANDIDATE,
  POST_COMMENTS_URL,
  SAVE_CHANNEL_URL,
  SEND_EVENT,
  SEND_EVENT_RESULT,
} from '@constants/url'

import { ChannelType } from '@custom-types/channel'

import { IniateValues } from '../components/user-action-modal/UserActionModal'
import { ChatFilterOptionsV2, FeedbackStatusType } from '../types'

export type CreateInterviewProps = {
  jobTitle: string
  data: {
    eventName: string
    startTimestamp: string
    endTimestamp: string
    mapPlaceId: string
    locationTitle: string
    locationAddress: string
    latitude: number
    longitude: number
    notes: string
    isOnline: boolean
    onlineLink?: string
  }
}
export type UpdateInterviewProps = CreateInterviewProps & {
  eventId: string
}
export type SendInterviewProps = {
  eventId: string
  data: {
    jobPostId?: string
    candidateId?: string
  }
}

export type SendInterviewResultProps = {
  eventId: string
  data: {
    employerFeedbackRating: number | null
    employerFeedbackStatus: string | null
    employerNotes: string | null
  }
}

export const mapApplicationStatusFilterKeys = {
  InReview: 'Melamar',
  AssessmentPassed: 'Lolos asesmen',
  Shortlisted: 'Shortlisted',
  Rejected: 'Rejected',
  InterviewScheduled: 'Interview',
} as const

export type ApplicationStatusFilterKeys =
  keyof typeof mapApplicationStatusFilterKeys

type StateProps = {
  sendbirdChannelURL: string
  sendbirdAccessToken: string
  sendbirdUserId: string
  sendbirdAppId: string
  sendbirdChatCount: number
  openEndChatModal: boolean
  openInviteInterviewModal: boolean
  filterOptions: ChatFilterOptionsV2
  applicationStatusFilter: ApplicationStatusFilterKeys
  userData?: ChannelType
  detailLoker: {
    jobPostId: string
    isModalOpen: boolean
  }
  sendEventData: {
    eventId: string
    data: {
      jobPostId?: string
      candidateId?: string
    }
  }
  sendInterviewResult: {
    eventId: string
    data: {
      employerFeedbackStatus: FeedbackStatusType | ''
      employerFeedbackRating: number | null
      employerNotes: string
    }
  }
  candidateDrawer: {
    open: boolean
  }
}

export const postChannel = createAsyncThunk(
  'chats/postChannel',
  async (jobApplicationId: string, { rejectWithValue }) => {
    try {
      const res = await post(SAVE_CHANNEL_URL(jobApplicationId))
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const endChat = createAsyncThunk(
  'chats/endChat',
  async (
    {
      channelId,
      data,
    }: { channelId: string; data: { reason: string; reasonText: string } },
    { rejectWithValue }
  ) => {
    try {
      const res = await _delete(GET_CHANNEL_BY_ID_URL(channelId), data)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const reportCandidate = createAsyncThunk(
  'chats/reportCandidate',
  async (
    { candidateId, data }: { candidateId: string; data: IniateValues },
    { rejectWithValue }
  ) => {
    try {
      const res = await post(CANDIDATE_BYID_URL_REPORT(candidateId), data)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const blockCandidate = createAsyncThunk(
  'chats/reportCandidate',
  async (
    { candidateId, data }: { candidateId: string; data: IniateValues },
    { rejectWithValue }
  ) => {
    try {
      const res = await post(CANDIDATE_BYID_URL_BLOCK(candidateId), data)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type PostCommentProps = {
  jobPostId: string
  commentText: string
  parentCommentId?: string
}

export const postComment = createAsyncThunk(
  'chats/postComment',
  async (data: PostCommentProps, { rejectWithValue }) => {
    try {
      const commentData: { text: string; parentCommentId?: string } = {
        text: data.commentText,
      }

      if (data.parentCommentId) {
        commentData.parentCommentId = data.parentCommentId
      }

      const res = await post(POST_COMMENTS_URL(data.jobPostId), commentData)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

type DeleteCommentProps = {
  jobPostId: string
  commentId: string
}

export const deleteComment = createAsyncThunk(
  'chats/deleteComment',
  async (data: DeleteCommentProps, { rejectWithValue }) => {
    try {
      const res = await _delete(
        DELETE_COMMENTS_URL(data.jobPostId, data.commentId)
      )
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const createInterview = createAsyncThunk(
  'chat/createInterview',
  async (data: CreateInterviewProps, { rejectWithValue }) => {
    try {
      const res = await post(CREATE_EVENT, data.data)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateInterview = createAsyncThunk(
  'chat/updateInterview',
  async (data: UpdateInterviewProps, { rejectWithValue }) => {
    try {
      const res = await patch(EVENT_BY_ID_URL(data.eventId), data.data)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const sendInterview = createAsyncThunk(
  'chat/sendInterview',
  async (data: SendInterviewProps, { rejectWithValue }) => {
    try {
      const res = await post(SEND_EVENT(data.eventId), data.data)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const sendInterviewResult = createAsyncThunk(
  'chat/sendInterviewResult',
  async (data: SendInterviewResultProps, { rejectWithValue }) => {
    try {
      const res = await post(SEND_EVENT_RESULT(data.eventId), data.data)
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const hireCandidate = createAsyncThunk(
  'chat/sendInterviewResult',
  async (data: { eventId: string }, { rejectWithValue }) => {
    try {
      const res = await post(HIRE_CANDIDATE(data.eventId))
      return res.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
const initialState: StateProps = {
  sendbirdAccessToken: '',
  sendbirdChannelURL: '',
  sendbirdUserId: '',
  sendbirdAppId: process.env.NEXT_PUBLIC_SENDBIRD_APP_ID as string,
  sendbirdChatCount: 0,
  openEndChatModal: false,
  openInviteInterviewModal: false,
  filterOptions: {
    status: ['Active'],
    initiatedBy: ['Other'],
    uniqueTimestamp: Date.now(),
  },
  applicationStatusFilter: 'InReview',
  userData: {
    id: '',
    employerId: '',
    channelId: '',
    createdBy: '',
    status: '',
    unreadMessageCount: 0,
    isFavourited: false,
    lastActivityAt: '',
    actions: {
      canInviteForEvent: true,
    },
    eventInfo: {
      id: '',
      canInvite: false,
    },
    candidate: {
      id: '',
      fullName: '',
      gender: '',
      dateOfBirth: '',
      profilePictureURL: '',
      profileVideoURL: '',
      slug: '',
      latestWorkExperience: {
        id: '',
        location: {
          address: '',
          latitude: 0,
          longitude: 0,
          placeId: '',
          locationAddress: '',
          locationTitle: '',
          mapPlaceId: '',
          shortAddress: '',
        },
        description: '',

        industryId: '',
        medias: [],
        skillTags: [],
        toolTags: [],
        employmentType: null,
        companyId: '',
        companyLink: '',
        designation: '',
        companyName: '',
        industry: {
          id: '',
          industryName: '',
          category: '',
        },
        startDate: '',
        endDate: '',
        isWorking: false,
      },
      highestEducation: {
        institute: '',
        major: '',
        score: 0,
        degree: {
          id: '',
          degreeName: '',
        },
        startDate: '',
        endDate: '',
        isStudying: false,
      },
      documents: [],
    },
    jobPost: {
      id: '',
      title: '',
      minimumSalary: 0,
      maximumSalary: 0,
      employmentType: '',
      requiredExperienceInMonths: 0,
      company: {
        id: '',
        name: '',
        logoURL: '',
      },
    },
    screeningAssessmentInfo: {
      id: '',
      canSendAssessment: false,
    },
    jobApplication: {
      expiryAt: '',
    },
    lastMessage: {
      message: '',
    },
  },
  detailLoker: {
    jobPostId: '',
    isModalOpen: false,
  },
  sendEventData: {
    eventId: '',
    data: {
      jobPostId: '',
      candidateId: '',
    },
  },
  sendInterviewResult: {
    eventId: '',
    data: {
      employerFeedbackStatus: '',
      employerFeedbackRating: null,
      employerNotes: '',
    },
  },
  candidateDrawer: {
    open: false,
  },
}

const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    resetFilter: (state) => {
      state.filterOptions = {
        ...initialState.filterOptions,
      }
      state.sendbirdChannelURL = ''
    },
    setSenbirdAccessToken: (
      state,
      action: PayloadAction<StateProps['sendbirdAccessToken']>
    ) => {
      state.sendbirdAccessToken = action.payload
    },
    setSendbirdUserId: (
      state,
      action: PayloadAction<StateProps['sendbirdUserId']>
    ) => {
      state.sendbirdUserId = action.payload
    },
    setSendbirdChannelUrl: (
      state,
      action: PayloadAction<StateProps['sendbirdChannelURL']>
    ) => {
      state.sendbirdChannelURL = action.payload
    },
    setSendbirdChatCount: (
      state,
      action: PayloadAction<StateProps['sendbirdChatCount']>
    ) => {
      state.sendbirdChatCount = action.payload
    },
    setOpenEndChatModal: (
      state,
      action: PayloadAction<StateProps['openEndChatModal']>
    ) => {
      state.openEndChatModal = action.payload
    },
    setOpenInviteInterviewModal: (
      state,
      action: PayloadAction<StateProps['openInviteInterviewModal']>
    ) => {
      state.openInviteInterviewModal = action.payload
    },
    setFilterOptions: (
      state,
      action: PayloadAction<Partial<StateProps['filterOptions']>>
    ) => {
      state.filterOptions = {
        ...state.filterOptions,
        ...action.payload,
        uniqueTimestamp: Date.now(),
      }
    },
    setApplicationStatusFilter(
      state,
      action: PayloadAction<StateProps['applicationStatusFilter']>
    ) {
      state.applicationStatusFilter = action.payload
    },
    setUserData(state, action: PayloadAction<ChannelType>) {
      state.userData = action.payload
    },
    setDetailLoker(
      state,
      action: PayloadAction<{ jobPostId: string; isModalOpen: boolean }>
    ) {
      state.detailLoker = action.payload
    },
    resetFilterOptions(state) {
      state.filterOptions = {
        ...initialState.filterOptions,
        uniqueTimestamp: Date.now(),
      }
    },
    setSendEventData(
      state,
      action: PayloadAction<StateProps['sendEventData']>
    ) {
      state.sendEventData = action.payload
    },
    setSendInterviewResult(
      state,
      action: PayloadAction<StateProps['sendInterviewResult']>
    ) {
      state.sendInterviewResult = action.payload
    },
    setCandidateDrawer(
      state,
      action: PayloadAction<StateProps['candidateDrawer']>
    ) {
      state.candidateDrawer = action.payload
    },
  },
})

export const chatsActions = chatsSlice.actions

export const useChatsActions = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators(chatsActions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}

export default chatsSlice.reducer
