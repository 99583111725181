import { InitOptions } from 'branch-sdk'

/* eslint-disable import/prefer-default-export */
export const initBranch = async () => {
  const BranchSDK = (await import('branch-sdk')).default

  const options: InitOptions = {
    no_journeys: true,
  }

  console.log('Branch - Initialize')
  BranchSDK.init(process.env.NEXT_PUBLIC_BRANCH_LIVE_KEY as string, options)
}

export const setBranchEvent = async <T>(
  event: string,
  eventProperties?: T
): Promise<void> => {
  const BranchSDK = (await import('branch-sdk')).default

  console.log(`Branch - ${event}`)
  BranchSDK.logEvent(event, eventProperties as object | undefined)
}
