export enum Access {
  LOGGED_IN = 'Access: Logged In',
  LOGGED_OUT = 'Access: Logged Out',
}

export enum JobSearch {
  VIEWED_JOB_LISTING_CANDIDATES = 'Job Search: Viewed Job Listing Candidates',
}

export enum JobApplication {
  FILTERED_CANDIDATES_BY_STATUS = 'Job Application: Filtered Candidates By Status',
  SORTED_CANDIDATES = 'Job Application: Sorted Candidates',
  FILTERED_CANDIDATES = 'Job Application: Filtered Candidates',
  VIEWED_SHORTLISTED_CANDIDATES = 'Job Application: Viewed Shortlisted Candidates',
  SUBMITTED_CANDIDATE_FEEDBACK = 'Job Application: Submitted Candidate Feedback',
  BULK_SUBMIT_CANDIDATE_FEEDBACK = 'Job Application: Bulk Submitted Candidate Feedback',
  DOWNLOADED_CANDIDATE_CV = 'Job Application:  Downloaded Candidate CV',
  SHORTLISTED_A_CANDIDATE = 'Job Application: Shortlisted a Candidate',
  BULK_SHORTLISTED_A_CANDIDATE = 'Job Application: Bulk Shortlisted a Candidate',
  UNSHORLISTED_A_CANDIDATE = 'Job Application: Unshortlisted a Candidate',
}

// *** ACCESS & LOGIN
const ACCESS = 'Access:' as const
const ACCESS_ENTERED_PHONE_NUMBER = `${ACCESS} Entered Phone Number` as const
const ACCESS_ENTERED_EMAIL_ADDRESS = `${ACCESS} Entered Email Address` as const
const ACCESS_VERIFIED_OTP = `${ACCESS} Verified OTP` as const
const ACCESS_RESENT_OTP = `${ACCESS} Resent OTP` as const
const ACCESS_RESENT_VERIFICATION_LINK =
  `${ACCESS} Resent Verification Email` as const
const ACCESS_CREATED_NEW_ACCOUNT = `${ACCESS} Created New Account` as const
const ACCESS_LOGGED_IN = `${ACCESS} Logged In` as const
const ACCESS_LOGGED_OUT = `${ACCESS} Logged Out` as const
const ACCESS_EDIT_COMPANY_PROFILE = `${ACCESS} Edited Company Profile` as const
const ACCESS_CLICKED_ON_CONTACT_SUPPORT =
  `${ACCESS} Clicked on Contact Support` as const
const ACCESS_CLICKED_ON_NEXT_COACHMARK =
  `${ACCESS} Clicked on Next Coachmark` as const
const ACCESS_CLICKED_ON_SKIPPED_COACHMARK =
  `${ACCESS} Clicked on Skipped Coachmark` as const
const ACCESS_CLICKED_ON_FINISH_COACHMARK =
  `${ACCESS} Clicked on Finish Coachmark` as const
const ACCESS_CHOSE_OTP_OPTION = `${ACCESS} Chose OTP Option`

// *** ONBOARDING
const ONBOARDING = 'Onboarding:' as const
const ONBOARDING_ENTERED_USER_DETAILS =
  `${ONBOARDING} Entered User Details` as const
const ONBOARDING_ENTERED_COMPANY_PROFILE =
  `${ONBOARDING} Entered Company Details` as const
const ONBOARDING_CREATED_A_COMPANY = `${ONBOARDING} Created a Company`
const ONBOARDING_COMPLETED = `${ONBOARDING} Onboarding Completed`
const ONBOARDING_SKIPPED_COMPANY_PROFILE =
  `${ONBOARDING} Skipped Company Profile` as const

// *** JOB SEARCH
const JOB_SEARCH = 'Job Search:' as const
const JOB_SEARCH_ENTERED_JOB_LISTING_BASIC_INFORMATION =
  `${JOB_SEARCH} Creating a Job Listing - Submitted Basic Information` as const
const JOB_SEARCH_ENTERED_JOB_LISTING_ADDITIONAL_INFORMATION =
  `${JOB_SEARCH} Creating a Job Listing - Submitted Additional Information` as const
const JOB_SEARCH_ENTERED_JOB_LISTING_VISUAL_CONTENT =
  `${JOB_SEARCH} Creating a Job Listing - Submitted Visual Content` as const
const JOB_SEARCH_SUBMITTED_A_JOB_LISTING =
  `${JOB_SEARCH} Creating a Job Listing - Reviewed and Confirmed Submission` as const
const JOB_SEARCH_EDIT_JOB_LISTING =
  `${JOB_SEARCH} Edited a Job Listing` as const
const JOB_SEARCH_VIEW_JOB_LISTING =
  `${JOB_SEARCH} Viewed Job Listing Candidates` as const
const JOB_SEARCH_SEARCHED_FOR_CANDIDATE =
  `${JOB_SEARCH} Searched For a Candidate` as const
const JOB_SEARCH_CLICKED_ON_POST_NEW_JOB =
  `${JOB_SEARCH} Clicked on Post New Job` as const
const JOB_SEARCH_ACTIVATE_JOB_LISTING =
  `${JOB_SEARCH} Activated a Job Listing` as const
const JOB_SEARCH_DEACTIVATE_JOB_LISTING =
  `${JOB_SEARCH} Deactivated a Job Listing` as const
const JOB_SEARCH_REPOSTED_JOB_LISTING =
  `${JOB_SEARCH} Reposted a Job Listing` as const

const JOB_SEARCH_CLICK_CARI_KANDIDAT = 'Job Search: Clicked on Cari Kandidat'

// *** JOB APPLICATION
const JOB_APPLICATION = `Job Application:` as const
const JOB_APPLICATION_SHORTLISTED_A_CANDIDATE =
  `${JOB_APPLICATION} Shortlisted a Candidate` as const
const JOB_APPLICATION_REJECTED_A_CANDIDATE =
  `${JOB_APPLICATION} Rejected a Candidate` as const
const JOB_APPLICATION_UPDATED_STATUS =
  `${JOB_APPLICATION} Updated Status` as const
const JOB_APPLICATION_VIEWED_A_DOCUMENT =
  `${JOB_APPLICATION} Viewed a Document` as const
const JOB_APPLICATION_DOWNLOADED_A_DOCUMENT =
  `${JOB_APPLICATION} Downloaded a Document` as const
const JOB_APPLICATION_REPOSTED_A_JOB_LISTING =
  `${JOB_APPLICATION} Reposted a Job Listing` as const
const JOB_APPLICATION_PLAYED_A_VIDEO_PROFILE =
  `${JOB_APPLICATION} Played a Video Profile` as const
const JOB_APPLICATION_CLICKED_ON_CHAT =
  `${JOB_APPLICATION} Clicked on Chat` as const
const JOB_APPLICATION_CLICKED_ON_WHATSAPP =
  `${JOB_APPLICATION} Clicked on WhatsApp` as const
const JOB_APPLICATION_CLICKED_ON_WEB_PROFILE_CV =
  `${JOB_APPLICATION} Clicked View CV` as const
const JOB_APPLICATION_VIEWED_A_CANDIDATE_PROFILE =
  `${JOB_APPLICATION} Viewed a Candidate Profile` as const
const JOB_APPLICATION_FILTERED_CANDIDATES =
  `${JOB_APPLICATION} Filtered Candidates` as const
const JOB_APPLICATION_ATTEMPTED_TO_VIEW_DOCUMENT =
  `${JOB_APPLICATION} Attempted to View Document` as const
const JOB_APPLICATION_ATTEMPTED_TO_DOWNLOAD_DOCUMENT =
  `${JOB_APPLICATION} Attempted to Download Document` as const
const JOB_APPLICATION_SUCCESSFULLY_SENT_AN_INVITATION =
  `${JOB_APPLICATION} Successfully Sent an Invitation` as const
const JOB_APPLICATION_CLICK_ON_SEE_RECOMMENDATION_TAB_BUTTON =
  `${JOB_APPLICATION} Clicked on See Recommendation Tab Button` as const
const JOB_APPLICATION_CONFIRMED_INTENT_TO_INVITE =
  `${JOB_APPLICATION} Confirmed Intent to Invite` as const
const JOB_APPLICATION_CLICKED_ON_INVITE_CANDIDATE =
  `${JOB_APPLICATION} Clicked on Invite Candidate` as const
const JOB_APPLICATION_CLICKED_ON_REVEAL_CANDIDATE_PHONE_NUMBER =
  `${JOB_APPLICATION} Revealed Candidate Phone Number` as const

// *** CHAT
const CHAT = `Chat:` as const
const CHAT_SEND_A_MESSAGE = `${CHAT} Sent a Message` as const
const CHAT_ATTACHED_A_FILE = `${CHAT} Attached a File` as const
const CHAT_CHANGED_JOB = `${CHAT} Changed Job` as const
const CHAT_VIEWED_JOB_DETAIL = `${CHAT} Viewed Job Listing Details` as const
const CHAT_VIEWED_CANDIDATE_DETAIL = `${CHAT} Viewed Candidate Details` as const
const CHAT_REPORTED_A_USER = `${CHAT} Reported a User` as const
const CHAT_BLOCKED_A_USER = `${CHAT} Blocked a user`
const CHAT_FILTERED_CHAT_CHANNELS = `${CHAT} Filtered Chat Channels` as const
const CHAT_SENT_INTERVIEW =
  `${CHAT} Sent an Interview Event Invitation` as const
const CHAT_FROM_SELF = `${CHAT} Clicked on Dari Saya` as const
const CHAT_FROM_OTHERS = `${CHAT} Clicked on Dari Pelamar` as const
const CHAT_REQUESTED = `${CHAT} Clicked on Pending` as const
const CHAT_CLICK_ON_INVITE_TO_INTERVIEW =
  `${CHAT} Clicked on Invite to Interview` as const
const CHAT_SHORTLIST_CANDIDATE = `${CHAT} Shortlisted a Candidate` as const
const CHAT_CLICK_ON_REJECT_APPLICANT =
  `${CHAT} Clicked on Reject Applicant` as const
const CHAT_END_CHAT = `${CHAT} Ended Chat` as const
const CHAT_CLICK_SHARE_INTERVIEW_RESULT =
  `${CHAT} Clicked on Bagikan hasil interview` as const
const CHAT_SUBMIT_INTERVIEW_RESULT = `${CHAT} Submit Interview Result` as const
const CHAT_CHANGE_INTERVIEW_RESULT = `${CHAT} Change Interview Result` as const
const CHAT_CLICK_HIRE = `${CHAT} Clicked on Diterima` as const
const CHAT_CONFIRMATION_HIRING_STATUS = `${CHAT} Confirm Hiring Status` as const

// *** CANDIDATE SEARCH
const CANDIDATE_SEARCH = `Candidate Search:` as const
const CANDIDATE_SEARCH_CLICKED_ON_CHAT =
  `${CANDIDATE_SEARCH} Clicked on Chat` as const
const CANDIDATE_SEARCH_VIEWED_CANDIDATE_DETAILS =
  `${CANDIDATE_SEARCH} Viewed Candidate Details` as const
const CANDIDATE_SEARCH_FILTERED_BY_JOB_TYPE =
  `${CANDIDATE_SEARCH} Filtered by Job Type` as const
const CANDIDATE_SEARCH_FILTERED_BY_EDUCATION_LEVEL =
  `${CANDIDATE_SEARCH} Filtered by Education Level` as const
const CANDIDATE_SEARCH_FILTERED_BY_LOCATION =
  `${CANDIDATE_SEARCH} Filtered by Location` as const
const CANDIDATE_SEARCH_FILTERED_BY_YEARS_OF_EXPERIENCE =
  `${CANDIDATE_SEARCH} Filtered by Years of Experience` as const
const CANDIDATE_SEARCH_VIEWED_A_DOCUMENT =
  `${CANDIDATE_SEARCH} Viewed a Document` as const
const CANDIDATE_SEARCH_DOWNLOADED_A_DOCUMENT =
  `${CANDIDATE_SEARCH} Downloaded a Document` as const
const CANDIDATE_SEARCH_PLAYED_A_VIDEO_PROFILE =
  `${CANDIDATE_SEARCH} Played a Video Profile` as const

// LEAD GEN
const LEAD_GEN_JP_INFO_STEP = `Lead Gen: Completed JP Info Step` as const
const LEAD_GEN_SUBMIT_JOB_LISTING =
  `Lead Gen: Submitted Job Listing Info` as const

// *** KYC
const KYC = 'KYC:'
const KYC_CREATED_A_COMPANY = `${KYC} Created a Company`
const KYC_CONFIRMED_PRE_KYC = `${KYC} Confirmed Name and Company Details`
const KYC_STARTED_VERIFICATION_FLOW = `${KYC} Started Verification Flow`
const KYC_SUBMITTED_PERSONAL_DATA = `${KYC} Submitted Personal KYC Details`
const KYC_SUBMITTED_COMPANY_DATA = `${KYC} Submitted Company KYC Details`
const KYC_SUBMITTED_ADDITIONAL_INFO = `${KYC} Submitted Additional Information`
const KYC_COMPLETED_KYC = `${KYC} Completed KYC`

// *** INTERVIEW
const INTERVIEW = 'Interview:'
const INTERVIEW_CLICKED_CREATE_INTERVIEW = `${INTERVIEW} Clicked on Create New Interview`
const INTERVIEW_CREATED_INTERVIEW = `${INTERVIEW} Created an Interview Event`
const INTERVIEW_DELETE_INTERVIEW = `${INTERVIEW} Deleted an Interview Event`
const INTERVIEW_EDITED_INTERVIEW = `${INTERVIEW} Edited an Interview Event`
const INTERVIEW_VIEW_CANDIDATE_LIST = `${INTERVIEW} Viewed Candidate List`
const INTERVIEW_OPEN_MAP = `${INTERVIEW} Clicked on Open Map`
const INTERVIEW_FILTERED_CANDIDATE = `${INTERVIEW} Filtered Candidates by Job Listing`

const CANDIDATES_SOCIAL_MEDIA_LINK_CLICKED =
  'Candidates: Clicked on Social Media Link'
const CANDIDATES_INTRO_VIDEO_BUTTON_CLICKED =
  'Candidates: Clicked on Introduction Video'
const CANDIDATES_FULL_BODY_BUTTON_CLICKED =
  'Candidates: Clicked on Full Body Photo'

const SEARCH_CANDIDATE_FILTER = 'Cari: Filtered Candidates'
const SEARCH_CANDIDATE_CLICKED = 'Cari: Clicked on Cari Kandidat'
const SEARCH_CANDIDATE_VIEW_CANDIDATE_PROFILE_CLICKED =
  'Cari: Clicked on View Candidate Profile'
const SEARCH_CANDIDATE_CANDIDATE_LIST_LOADED = 'Cari: Loaded Candidate List'

const SEARCH_CANDIDATE_INVITE_TO_INTERVIEW = 'Cari: Invited to Interview'
const SEARCH_CANDIDATE_INVITE_TO_APPLY = 'Cari: Invited to Apply'
const SEARCH_CANDIDATE_CLICK_ON_MESSAGE_TEMPLATE =
  'Cari: Clicked on Message Template'
const SEARCH_CANDIDATE_CLICK_INTERVIEW_INVITATION =
  'Cari: Clicked on Add Interview Invitation'
const SEARCH_CANDIDATE_SENT_INVITATION = 'Cari: Sent Invitation'
const SEARCH_CHANGE_SELECTED_JL = 'Cari: Changed Selected JL'
const SEARCH_CANCEL_COMPOSE_MESSAGE = 'Cari: Cancelled Compose Message'

const BOOST_CLICK_BOOST_BUTTON_JOB_SCREEN = 'Job Search: Clicked on Boost'
const BOOST_CLICK_BOOST_BANNER_CANDIDATE_SCREEN = 'Cari: Clicked on Boost'
const BOOST_CHANGE_SELECTED_JL = 'Boost: Changed Selected JL'
const BOOST_LOADED_AVAILABLE_RECIPIENT = 'Boost: Loaded Available Recipient'
const BOOST_SELECT_INVTE_TO_APPLY = 'Boost: Invited to Apply'
const BOOST_SELECT_INVTE_TO_INTERVIEW = 'Boost: Invited to Interview'
const BOOST_CLICK_MESSAGE_TEMPLATE = 'Boost: Clicked on Message Template'
const BOOST_CLICK_ADD_INTERVIEW_BUTTON =
  'Boost: Clicked on Add Interview Invitation'
const BOOST_CLICK_SUBMIT_SEND_INVITATION = 'Boost: Sent Invitation'
const BOOST_FILTER_CANDIDATE = 'Boost: Filtered Candidates'
const BOOST_CANCEL_COMPOSE_MESSAGE = 'Boost: Cancelled Compose Message'

const COINS_CLICK_TOPUP_BUTTON = 'Coins: Clicked on Topup Button'
const COINS_SELECT_PACKAGE_OPTION = 'Coins: Clicked on Package Option'
const COINS_CLICK_PAY_BUTTON = 'Coins: Clicked on Payment Button'
const COINS_REDIRECTED_TO_PAYMENT_METHOD_PAGE =
  'Coins: Redirected to Payment Method Page'
const COINS_REDIRECTED_TO_TOPUP_STATUS_PAGE =
  'Coins: Redirected to Topup Status Page'
const COINS_SELECT_SPECIAL_PACKAGE_OPTION =
  'Coins: Clicked on Special Package Option'
const COINS_OPEN_PRICELIST = 'Coins: Clicked on the Pricelist Button'
const COINS_OPEN_TRANSACTION_LIST = 'Coins: Clicked on Transaction List'
const COINS_OPEN_TRANSACTION_DETAIL = 'Coins: Clicked on Transaction Detail'
const COINS_DEDUCTION_ATTEMPT = 'Coins: Deduction Attempted'
const COINS_DEDUCTION_SUCCESS = 'Coins: Deduction Success'
const COINS_DEDUCTION_FAILED = 'Coins: Deduction Failed'
const COINS_DEDUCTION_CANCELATION = 'Coins: Clicked on Cancel Topup'
const COINS_DEDUCTION_INSUFFICIENT_BALANCE = 'Coins: Insufficient Fund Balance'
const COINS_VOUCHER_TAB_CLICKED = 'Coins: Clicked on Voucher Tab'
const COINS_VOUCHER_VALIDATE_SUCCESS = 'Coins: Validated Voucher Successfully'
const COINS_VOUCHER_VALIDATE_FAILED = 'Coins: Failed Voucher Validation'
const COINS_VOUCHER_REDEMPTION_SUCCESS = 'Coins: Redeemed Voucher Successfully'
const CLICK_ON_LOKER_PAGE = 'Clicked on Loker Page'
const CLICK_ON_POST_NEW_JOB = 'Clicked on Post New Job'
const CLICK_ON_REPOST_JOB = 'Clicked on Repost Job'
const REPOSTED_JOB = 'Reposted a Job'
const CLICK_ON_REJECT_APPLICANT = 'Candidates: Clicked on Reject Applicant'
const CLICK_ON_REPLY_APPLICANT = 'Candidates: Clicked on Reply Applicant'
const CLICK_ON_SEE_DOKUMENT = 'Clicked on Lihat Dokumen'
const CLICK_ON_SEE_DOKUMENT_CONFIRMATION = 'Clicked on Konfirmasi Lihat Dokumen'
const CLICK_ON_WHATSAPP = 'Clicked on Whatsapp'
const CLICK_ON_WHATSAPP_CONFIRMATION = 'Clicked on Konfirmasi Whatsapp'

const TALENT_POOL_ENTRY_POINT =
  'Candidates: Clicked on Talent Pool Entry Point (JP)'

const TALENT_POOL_CLICK_FROM_CARI_PAGE =
  'Cari: Clicked on Save to Talent Pool CTA (JP)'
const TALENT_POOL_SAVE_FROM_LAMARAN_PAGE =
  'Candidates: Clicked on Save to Talent Pool CTA (JP)'

const TALENT_POOL_SAVE_JS_PROFILE_PAGE =
  'JS Profile: Clicked on Save to Talent Pool CTA (JP)'
const TALENT_POOL_SAVE_TO_NEW_POOL =
  'Talent Pool: Clicked on Simpan to a new pool (JP)'
const TALENT_POOL_SAVE_TO_EXISTING =
  'Talent Pool: Clicked on Simpan to an existing pool (JP)'
const TALENT_POOL_CLICK_CHANGE_POOL = 'Talent Pool: Clicked on “Ubah Pool” (JP)'
const TALENT_POOL_CLICK_DELETE_FROM_POOL =
  'Talent Pool: Clicked on “Hapus Dari Pool” (JP)'
const TALENT_POOL_SAVE_FROM_CHANGE_POOL =
  'Talent Pool: Clicked on “Simpan” from “Ubah Pool” (JP)'
const TALENT_POOL_CONTINUE_ON_DELETE_POOL =
  'Talent Pool: Clicked on “Lanjut” from “Hapus Dari Pool” (JP)'
const TALENT_POOL_CLICK_INVITE_TO_INTERVIEW_TO_ALL =
  'Talent Pool: Clicked on “Undang interview ke semua kandidat” (JP)'
const TALENT_POOL_CLICK_INVITE_TO_APPLY_TO_ALL =
  'Talent Pool: Clicked on “Tawarkan loker ke semua kandidat” (JP)'
const TALENT_POOL_SEND_INVITE_TO_INTERVIEW_TO_ALL =
  'Talent Pool: Clicked on “Kirim” from “Undang interview ke semua kandidat” (JP)'
const TALENT_POOL_SEND_INVITE_TO_APPLY_TO_ALL =
  'Talent Pool: Clicked on “Kirim” from “Tawarkan loker ke semua kandidat” (JP)'
const TALENT_POOL_CLICK_A_POOL = 'Talent Pool: Clicked on a pool (JP)'
const TALENT_POOL_CLICK_EDIT_POOL = 'Talent Pool: Clicked on “Edit Pool” (JP)'
const TALENT_POOL_SAVE_EDITED_POOL =
  'Talent Pool: Clicked on “Simpan” from “Edit Pool” (JP)'
const TALENT_POOL_CLICK_DELETED_POOL =
  'Talent Pool: Clicked on “Hapus Pool” (JP)'
const TALENT_POOL_CONTINUE_DELETE_POOL =
  'Talent Pool: Clicked on “Lanjut” from “Hapus Pool” (JP)'
const TALENT_POOL_CLICK_ACTIVATE_POOL =
  'Talent Pool: Clicked on “Aktifkan Pool” (JP)'
const TALENT_POOL_CLICK_CREATE_NEW_POOL =
  'Talent Pool: Clicked on “Buat Pool Baru” (JP)'
const TALENT_POOL_SAVE_NEW_POOL =
  'Talent Pool: Clicked on “Simpan” from creating a Pool (JP)'
const TALENT_POOL_CLICK_ON_JS_PROFILE =
  'Talent Pool: Clicked on  JS Profile (JP)'
const TALENT_POOL_CLICK_INVITE_TO_INTERVIEW_FOR_A_JS =
  'Talent Pool: Clicked on “Undang Interview” from a pool for a JS (JP)'
const TALENT_POOL_CLICK_INVITE_TO_APPLY_FOR_A_JS =
  'Talent Pool: Clicked on “Tawarkan Loker” from a pool for a JS (JP)'
const TALENT_POOL_SEND_INVITE_TO_INTERVIEW_FOR_A_JS =
  'Talent Pool: Clicked on “Kirim” on “Undang Interview” for a JS (JP)'
const TALENT_POOL_SEND_INVITE_TO_APPLY_FOR_A_JS =
  'Talent Pool: Clicked on “Kirim” on “Tawarkan Loker” for a JS (JP)'
const TALENT_POOL_CLICK_INVITE_TO_INTERVIEW_FOR_MULTIPLE_JS =
  'Talent Pool: Clicked on “Undang Interview” from a pool for Multiple JS (JP)'
const TALENT_POOL_CLICK_INVITE_TO_APPLY_FOR_MULTIPLE_JS =
  'Talent Pool: Clicked on “Tawarkan Loker” from a pool for Multiple JS (JP)'
const TALENT_POOL_SEND_INVITE_TO_INTERVIEW_FOR_MULTIPLE_JS =
  'Talent Pool: Clicked on “Kirim” on “Undang Interview” for multiple JS (JP)'
const TALENT_POOL_SEND_INVITE_TO_APPLY_FOR_MULTIPLE_JS =
  'Talent Pool: Clicked on “Kirim” on “Tawarkan Loker” for multiple JS (JP)'

// *** PREMIUM SERVICE
const CLICK_ON_CANDIDATE_PAGE =
  'Premium Service: Clicked on the “Kandidat” page (JP)'
const CLICK_ON_TALENT_POOL_PAGE =
  'Premium Service: Clicked on the “Talent Pool” page (JP)'
const CLICK_ON_INTERVIEW_PAGE =
  'Premium Service: Clicked on the “Interview” page (JP)'
const CLICK_ON_ACCOUNT_PAGE = 'Premium Service: Clicked on the “Akun” page (JP)'
const CLICK_ON_JP_PROFILE_FROM_CANDIDATE_PAGE =
  'Premium Service: Clicked on JP Profile on the “Kandidat” page(JP)'
const CLICK_ON_SESUAI_FROM_CANDIDATE_PAGE =
  'Premium Service: Clicked on “Sesuai” button from “Kandidat” Page (JP)'
const CLICK_ON_TIDAK_SESUAI_FROM_CANDIDATE_PAGE =
  'Premium Service: Clicked on “Tidak Sesuai” button from “Kandidat” Page (JP)'
const CLICK_ON_BATALKAN_SHORTLIST_FROM_CANDIDATE_PAGE =
  'Premium Service: Clicked on “Batalkan Shortlist” button from “Kandidat” Page (JP)'
const CLICK_ON_SAVE_TO_TALENT_POOL_FROM_CANDIDATE_PAGE =
  'Premium Service: Clicked on “Save to Talent Pool” button from “Kandidat” Page (JP)'
const CLICK_ON_SEE_DETAIL_FROM_CANDIDATE_PAGE =
  'Premium Service: Clicked on “Lihat Detail” button from “Kandidat” Page (JP)'
const CLICK_ON_CHANGE_JOB_FROM_CANDIDATE_PAGE =
  'Premium Service: Clicked on “Ganti Loker” button from “Kandidat” Page (JP)'
const CLICK_ON_APPLY_FROM_CHANGE_JOB =
  'Premium Service: Clicked on “Terapkan” from “Ganti Loker”(JP)'
const CLICK_SEE_LIST_OF_ATTENDESS_FROM_INTERVIEW_PAGE =
  'Premium Service: Clicked on “Lihat Daftar Hadir” from “Interview” Page (JP)'
const CLICK_JP_PROFILE_FROM_INTERVIEW_PAGE =
  'Premium Service: Clicked on  JP profile from “Interview” Page (JP)'
const CLICK_SEE_EVENT_DETAIL_FROM_INTERVIEW_PAGE =
  'Premium Service: Clicked on “Lihat Detail Event” from “Interview” Page (JP)'
const CLICK_APPLY_FROM_CHANGE_EVENT =
  'Premium Service: Clicked on “Ganti Event” from “Interview” Page (JP)'
const CLICK_SHARE_RESULT_FROM_INTERVIEW_PAGE =
  'Premium Service: Clicked on “Bagikan Hasil” from “Interview” Page Page (JP)'
const CLICK_SEND_FROM_SHARE_RESULT =
  'Premium Service: Clicked on “Kirim” from “Bagikan Hasil” Page (JP)'
const CLICK_SEE_RESULT_CTA_FROM_INTERVIEW_PAGE =
  'Premium Service: Clicked on  Edit Result CTA from “Interview” Page (JP)'
const CLICK_CHANGE_RESULT =
  'Premium Service: Clicked on “Ubah Hasil” button from See result CTA Page(JP)'
const CLICK_SEND_FROM_CHANGE_RESULT =
  'Premium Service: Clicked on “Kirim” from “Ubah Hasil” Page (JP)'

export {
  // *** Access & Login
  ACCESS_ENTERED_PHONE_NUMBER,
  ACCESS_ENTERED_EMAIL_ADDRESS,
  ACCESS_VERIFIED_OTP,
  ACCESS_RESENT_OTP,
  ACCESS_RESENT_VERIFICATION_LINK,
  ACCESS_CREATED_NEW_ACCOUNT,
  ACCESS_LOGGED_IN,
  ACCESS_LOGGED_OUT,
  ACCESS_EDIT_COMPANY_PROFILE,
  ACCESS_CLICKED_ON_CONTACT_SUPPORT,
  ACCESS_CLICKED_ON_NEXT_COACHMARK,
  ACCESS_CLICKED_ON_SKIPPED_COACHMARK,
  ACCESS_CLICKED_ON_FINISH_COACHMARK,
  ACCESS_CHOSE_OTP_OPTION,
  // *** Onboarding Process
  ONBOARDING_ENTERED_USER_DETAILS,
  ONBOARDING_ENTERED_COMPANY_PROFILE,
  ONBOARDING_SKIPPED_COMPANY_PROFILE,
  ONBOARDING_CREATED_A_COMPANY,
  ONBOARDING_COMPLETED,
  // *** Job Search
  JOB_SEARCH_ENTERED_JOB_LISTING_BASIC_INFORMATION,
  JOB_SEARCH_ENTERED_JOB_LISTING_ADDITIONAL_INFORMATION,
  JOB_SEARCH_ENTERED_JOB_LISTING_VISUAL_CONTENT,
  JOB_SEARCH_EDIT_JOB_LISTING,
  JOB_SEARCH_VIEW_JOB_LISTING,
  JOB_SEARCH_SEARCHED_FOR_CANDIDATE,
  JOB_SEARCH_SUBMITTED_A_JOB_LISTING,
  JOB_SEARCH_ACTIVATE_JOB_LISTING,
  JOB_SEARCH_DEACTIVATE_JOB_LISTING,
  JOB_SEARCH_REPOSTED_JOB_LISTING,
  JOB_SEARCH_CLICKED_ON_POST_NEW_JOB,
  JOB_SEARCH_CLICK_CARI_KANDIDAT,
  // *** Job Application
  JOB_APPLICATION_SHORTLISTED_A_CANDIDATE,
  JOB_APPLICATION_REJECTED_A_CANDIDATE,
  JOB_APPLICATION_UPDATED_STATUS,
  JOB_APPLICATION_VIEWED_A_DOCUMENT,
  JOB_APPLICATION_DOWNLOADED_A_DOCUMENT,
  JOB_APPLICATION_REPOSTED_A_JOB_LISTING,
  JOB_APPLICATION_PLAYED_A_VIDEO_PROFILE,
  JOB_APPLICATION_CLICKED_ON_CHAT,
  JOB_APPLICATION_CLICKED_ON_WHATSAPP,
  JOB_APPLICATION_CLICKED_ON_WEB_PROFILE_CV,
  JOB_APPLICATION_VIEWED_A_CANDIDATE_PROFILE,
  JOB_APPLICATION_FILTERED_CANDIDATES,
  JOB_APPLICATION_ATTEMPTED_TO_VIEW_DOCUMENT,
  JOB_APPLICATION_ATTEMPTED_TO_DOWNLOAD_DOCUMENT,
  JOB_APPLICATION_SUCCESSFULLY_SENT_AN_INVITATION,
  JOB_APPLICATION_CLICK_ON_SEE_RECOMMENDATION_TAB_BUTTON,
  JOB_APPLICATION_CONFIRMED_INTENT_TO_INVITE,
  JOB_APPLICATION_CLICKED_ON_INVITE_CANDIDATE,
  JOB_APPLICATION_CLICKED_ON_REVEAL_CANDIDATE_PHONE_NUMBER,
  // *** Chat
  CHAT_SEND_A_MESSAGE,
  CHAT_ATTACHED_A_FILE,
  CHAT_CHANGED_JOB,
  CHAT_BLOCKED_A_USER,
  CHAT_FILTERED_CHAT_CHANNELS,
  CHAT_REPORTED_A_USER,
  CHAT_VIEWED_CANDIDATE_DETAIL,
  CHAT_VIEWED_JOB_DETAIL,
  CHAT_SENT_INTERVIEW,
  CHAT_FROM_OTHERS,
  CHAT_FROM_SELF,
  CHAT_REQUESTED,
  // *** Candidate Search
  CANDIDATE_SEARCH_CLICKED_ON_CHAT,
  CANDIDATE_SEARCH_VIEWED_CANDIDATE_DETAILS,
  CANDIDATE_SEARCH_FILTERED_BY_JOB_TYPE,
  CANDIDATE_SEARCH_FILTERED_BY_EDUCATION_LEVEL,
  CANDIDATE_SEARCH_FILTERED_BY_LOCATION,
  CANDIDATE_SEARCH_FILTERED_BY_YEARS_OF_EXPERIENCE,
  CANDIDATE_SEARCH_VIEWED_A_DOCUMENT,
  CANDIDATE_SEARCH_DOWNLOADED_A_DOCUMENT,
  CANDIDATE_SEARCH_PLAYED_A_VIDEO_PROFILE,
  // *** Lead Gen
  LEAD_GEN_JP_INFO_STEP,
  LEAD_GEN_SUBMIT_JOB_LISTING,
  // *** KYC
  KYC_CREATED_A_COMPANY,
  KYC_CONFIRMED_PRE_KYC,
  KYC_STARTED_VERIFICATION_FLOW,
  KYC_SUBMITTED_PERSONAL_DATA,
  KYC_SUBMITTED_COMPANY_DATA,
  KYC_SUBMITTED_ADDITIONAL_INFO,
  KYC_COMPLETED_KYC,
  // *** INTERVIEW
  INTERVIEW_CLICKED_CREATE_INTERVIEW,
  INTERVIEW_DELETE_INTERVIEW,
  INTERVIEW_CREATED_INTERVIEW,
  INTERVIEW_EDITED_INTERVIEW,
  INTERVIEW_FILTERED_CANDIDATE,
  INTERVIEW_OPEN_MAP,
  INTERVIEW_VIEW_CANDIDATE_LIST,
  CANDIDATES_SOCIAL_MEDIA_LINK_CLICKED,
  CANDIDATES_INTRO_VIDEO_BUTTON_CLICKED,
  CANDIDATES_FULL_BODY_BUTTON_CLICKED,
  SEARCH_CANDIDATE_CLICKED,
  SEARCH_CANDIDATE_VIEW_CANDIDATE_PROFILE_CLICKED,
  SEARCH_CANDIDATE_CANDIDATE_LIST_LOADED,
  SEARCH_CANDIDATE_INVITE_TO_INTERVIEW,
  SEARCH_CANDIDATE_CLICK_ON_MESSAGE_TEMPLATE,
  SEARCH_CANDIDATE_CLICK_INTERVIEW_INVITATION,
  SEARCH_CANDIDATE_SENT_INVITATION,
  SEARCH_CHANGE_SELECTED_JL,
  SEARCH_CANCEL_COMPOSE_MESSAGE,
  SEARCH_CANDIDATE_FILTER,
  SEARCH_CANDIDATE_INVITE_TO_APPLY,
  BOOST_CLICK_BOOST_BUTTON_JOB_SCREEN,
  BOOST_CLICK_BOOST_BANNER_CANDIDATE_SCREEN,
  BOOST_CHANGE_SELECTED_JL,
  BOOST_LOADED_AVAILABLE_RECIPIENT,
  BOOST_SELECT_INVTE_TO_APPLY,
  BOOST_SELECT_INVTE_TO_INTERVIEW,
  BOOST_CLICK_MESSAGE_TEMPLATE,
  BOOST_CLICK_ADD_INTERVIEW_BUTTON,
  BOOST_CLICK_SUBMIT_SEND_INVITATION,
  BOOST_FILTER_CANDIDATE,
  BOOST_CANCEL_COMPOSE_MESSAGE,
  COINS_SELECT_PACKAGE_OPTION,
  COINS_CLICK_PAY_BUTTON,
  COINS_REDIRECTED_TO_PAYMENT_METHOD_PAGE,
  COINS_REDIRECTED_TO_TOPUP_STATUS_PAGE,
  COINS_SELECT_SPECIAL_PACKAGE_OPTION,
  COINS_OPEN_PRICELIST,
  COINS_OPEN_TRANSACTION_DETAIL,
  COINS_OPEN_TRANSACTION_LIST,
  COINS_DEDUCTION_ATTEMPT,
  COINS_DEDUCTION_SUCCESS,
  COINS_DEDUCTION_FAILED,
  COINS_DEDUCTION_CANCELATION,
  COINS_CLICK_TOPUP_BUTTON,
  COINS_DEDUCTION_INSUFFICIENT_BALANCE,
  COINS_VOUCHER_TAB_CLICKED,
  COINS_VOUCHER_VALIDATE_SUCCESS,
  COINS_VOUCHER_VALIDATE_FAILED,
  COINS_VOUCHER_REDEMPTION_SUCCESS,
  CLICK_ON_LOKER_PAGE,
  CLICK_ON_POST_NEW_JOB,
  CLICK_ON_REPOST_JOB,
  REPOSTED_JOB,
  CLICK_ON_REJECT_APPLICANT,
  CLICK_ON_REPLY_APPLICANT,
  CLICK_ON_SEE_DOKUMENT,
  CLICK_ON_SEE_DOKUMENT_CONFIRMATION,
  CLICK_ON_WHATSAPP,
  CLICK_ON_WHATSAPP_CONFIRMATION,
  CHAT_CLICK_ON_INVITE_TO_INTERVIEW,
  CHAT_SHORTLIST_CANDIDATE,
  CHAT_CLICK_ON_REJECT_APPLICANT,
  CHAT_END_CHAT,
  CHAT_CLICK_SHARE_INTERVIEW_RESULT,
  CHAT_SUBMIT_INTERVIEW_RESULT,
  CHAT_CHANGE_INTERVIEW_RESULT,
  CHAT_CLICK_HIRE,
  CHAT_CONFIRMATION_HIRING_STATUS,
  // *** INTERVIEW
  TALENT_POOL_CLICK_FROM_CARI_PAGE,
  TALENT_POOL_SAVE_FROM_LAMARAN_PAGE,
  TALENT_POOL_SAVE_JS_PROFILE_PAGE,
  TALENT_POOL_SAVE_TO_NEW_POOL,
  TALENT_POOL_SAVE_TO_EXISTING,
  TALENT_POOL_CLICK_CHANGE_POOL,
  TALENT_POOL_CLICK_DELETE_FROM_POOL,
  TALENT_POOL_SAVE_FROM_CHANGE_POOL,
  TALENT_POOL_CONTINUE_ON_DELETE_POOL,
  TALENT_POOL_CLICK_INVITE_TO_INTERVIEW_TO_ALL,
  TALENT_POOL_CLICK_INVITE_TO_APPLY_TO_ALL,
  TALENT_POOL_SEND_INVITE_TO_INTERVIEW_TO_ALL,
  TALENT_POOL_SEND_INVITE_TO_APPLY_TO_ALL,
  TALENT_POOL_CLICK_A_POOL,
  TALENT_POOL_CLICK_EDIT_POOL,
  TALENT_POOL_SAVE_EDITED_POOL,
  TALENT_POOL_CLICK_DELETED_POOL,
  TALENT_POOL_CONTINUE_DELETE_POOL,
  TALENT_POOL_CLICK_ACTIVATE_POOL,
  TALENT_POOL_CLICK_CREATE_NEW_POOL,
  TALENT_POOL_SAVE_NEW_POOL,
  TALENT_POOL_CLICK_ON_JS_PROFILE,
  TALENT_POOL_CLICK_INVITE_TO_INTERVIEW_FOR_A_JS,
  TALENT_POOL_CLICK_INVITE_TO_APPLY_FOR_A_JS,
  TALENT_POOL_SEND_INVITE_TO_INTERVIEW_FOR_A_JS,
  TALENT_POOL_SEND_INVITE_TO_APPLY_FOR_A_JS,
  TALENT_POOL_CLICK_INVITE_TO_INTERVIEW_FOR_MULTIPLE_JS,
  TALENT_POOL_CLICK_INVITE_TO_APPLY_FOR_MULTIPLE_JS,
  TALENT_POOL_SEND_INVITE_TO_INTERVIEW_FOR_MULTIPLE_JS,
  TALENT_POOL_SEND_INVITE_TO_APPLY_FOR_MULTIPLE_JS,
  TALENT_POOL_ENTRY_POINT,
  // *** INTERVIEW
  CLICK_ON_CANDIDATE_PAGE,
  CLICK_ON_TALENT_POOL_PAGE,
  CLICK_ON_INTERVIEW_PAGE,
  CLICK_ON_ACCOUNT_PAGE,
  CLICK_ON_JP_PROFILE_FROM_CANDIDATE_PAGE,
  CLICK_ON_SESUAI_FROM_CANDIDATE_PAGE,
  CLICK_ON_TIDAK_SESUAI_FROM_CANDIDATE_PAGE,
  CLICK_ON_BATALKAN_SHORTLIST_FROM_CANDIDATE_PAGE,
  CLICK_ON_SAVE_TO_TALENT_POOL_FROM_CANDIDATE_PAGE,
  CLICK_ON_SEE_DETAIL_FROM_CANDIDATE_PAGE,
  CLICK_ON_CHANGE_JOB_FROM_CANDIDATE_PAGE,
  CLICK_ON_APPLY_FROM_CHANGE_JOB,
  CLICK_SEE_LIST_OF_ATTENDESS_FROM_INTERVIEW_PAGE,
  CLICK_JP_PROFILE_FROM_INTERVIEW_PAGE,
  CLICK_SEE_EVENT_DETAIL_FROM_INTERVIEW_PAGE,
  CLICK_APPLY_FROM_CHANGE_EVENT,
  CLICK_SHARE_RESULT_FROM_INTERVIEW_PAGE,
  CLICK_SEND_FROM_SHARE_RESULT,
  CLICK_SEE_RESULT_CTA_FROM_INTERVIEW_PAGE,
  CLICK_CHANGE_RESULT,
  CLICK_SEND_FROM_CHANGE_RESULT,
}
