/* eslint-disable import/prefer-default-export */
import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { ApplicationsState } from '@features/applications/reducers/types'

export type BoostState = {
  page: 'candidate-filter' | 'write-invitation'
  queries: Omit<
    ApplicationsState['jobApplicantsFilterQueries'],
    'searchText' | 'page' | 'isFavourited' | 'sortOption' | 'sortType'
  >
}

const initialState: BoostState = {
  page: 'candidate-filter',
  queries: {},
}

export const boostSlice = createSlice({
  name: 'boost',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<BoostState['page']>) {
      state.page = action.payload
    },
    setQueries(state, action: PayloadAction<BoostState['queries']>) {
      state.queries = action.payload
    },
    resetQueries(state) {
      state.queries = initialState.queries
    },
  },
})

const { actions } = boostSlice

export const useBoostActions = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators(actions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}
