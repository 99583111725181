import React from 'react'

const OpenGraph: React.FC = () => (
  <>
    <meta
      name="title"
      content="Atma Business | Dapatkan Karyawan Idaman Cepat dan Mudah"
    />
    <meta
      name="description"
      content="Dapatkan karyawan tetap, harian lepas dan kontrak yang berkualifikasi dalam 2 hari saja dengan Atma Business. Dibantu oleh tim rekruter berdedikasi. Gratis 100% "
    />
    {/* // <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://business.atma.co.id/" />
    <meta
      property="og:title"
      content="Atma Business | Dapatkan Karyawan Idaman Cepat dan Mudah"
    />
    <meta
      property="og:description"
      content="Dapatkan karyawan tetap, harian lepas dan kontrak yang berkualifikasi dalam 2 hari saja dengan Atma Business. Dibantu oleh tim rekruter berdedikasi. Gratis 100% "
    />
    <meta
      property="og:image"
      content="https://atma-static-images.s3.ap-southeast-3.amazonaws.com/business-meta-image.png"
    />
    {/* // <!-- Twitter --> */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://business.atma.co.id/" />
    <meta
      property="twitter:title"
      content="Atma Business | Dapatkan Karyawan Idaman Cepat dan Mudah"
    />
    <meta
      property="twitter:description"
      content="Dapatkan karyawan tetap, harian lepas dan kontrak yang berkualifikasi dalam 2 hari saja dengan Atma Business. Dibantu oleh tim rekruter berdedikasi. Gratis 100% "
    />
    <meta
      property="twitter:image"
      content="https://atma-static-images.s3.ap-southeast-3.amazonaws.com/business-meta-image.png"
    />
    <meta
      name="facebook-domain-verification"
      content="30iatvuv8qo41f4ozyfhtenx9epuqh"
    />
  </>
)

export default OpenGraph
